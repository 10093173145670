// This file was automatically generated from consumer.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Consumer == 'undefined') { Nightloop.Templates.Consumer = {}; }


Nightloop.Templates.Consumer.Layout = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="consumer"><div id="stupidawfulcolorcover"></div><div id="header"><a href="/"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/logo-big.png" width="200" /></a></div><div id="menu"><a class="', (opt_data.navigation == 'ava-privacy') ? 'current' : '', '" href="/ava/privacy">Privacy</a><a class="', (opt_data.navigation == 'ava-terms') ? 'current' : '', '" href="/ava/terms">Terms of service</a><a class="', (opt_data.navigation == 'ava-contact') ? 'current' : '', '" href="/ava/contact">Contact</a><a class="', (opt_data.navigation == 'ava-open-source') ? 'current' : '', '" href="/ava/open-source">Open source licenses</a></div><div id="body">');
  Closure.Util.InsertTemplate(opt_data, output);
  output.append('</div></div>');
  return opt_sb ? '' : output.toString();
};
