// This file was automatically generated from admin.react.base.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.React = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Admin.ReactBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ReactBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/commons.js']), '" crossorigin="anonymous"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/core.js']), '" crossorigin="anonymous"><\/script><script type="text/javascript" src="">let map = ', soy.$$escapeHtml(opt_data.settings.CACHE_MAP), '<\/script><script type="text/javascript" charset="UTF-8" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/admin.js']), '"><\/script><div id="react-root"></div><script>window.SvrManager.', soy.$$escapeHtml(opt_data.renderItem), '.render(\'react-root\')<\/script>');
  return opt_sb ? '' : output.toString();
};
