var VMS = {
  
  init: function() 
  {
    this.setuplogos(); 
  },

  setuplogos: function() 
  {
    this.logos = $('#logo-browser');

    var that = this;
    $('#page-vms #logo-controls p').on('click', function() {
      that.scroll(this.className);
    });
  },

  scroll: function(dir)
  {
    var that = this
      , cellwidth = 120
      , arrowwidth = $('#logo-browser p.left').width()
      , width = $('#logo-browser').width() - arrowwidth*2;

    deductable = width % cellwidth;
    usable = width - deductable;

    if( dir == 'right' ) {
      usable *= -1;
    }

    that.logos.animate({ 'background-position-x': "+=" + usable });
  }
};

$(function() {
  VMS.init();
});

