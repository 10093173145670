// This file was automatically generated from direct.dining.reservations.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Direct == 'undefined') { Nightloop.Templates.Direct = {}; }


Nightloop.Templates.Direct.DiningReservations = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Direct.DiningReservationsBit(opt_data, output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Direct.DiningReservationsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="direct"><div id="page-diningreservations"><div class="content-header">Upcoming Reservations - ', soy.$$escapeHtml(opt_data.content.venue_name), '</div><div class="group-header">Response Required! (', soy.$$escapeHtml(opt_data.content.actuals_unconfirmed.length), ')</div><div class="ressies"><table>');
  Nightloop.Templates.Direct.ReservationGroup(soy.$$augmentData(opt_data, {actuals: opt_data.content.actuals_unconfirmed, star_contact_info: 0}), output);
  output.append('</table></div><div class="group-header">Upcoming Reservations (', soy.$$escapeHtml(opt_data.content.actuals_confirmed.length), ')</div><div class="ressies"><table>');
  Nightloop.Templates.Direct.ReservationGroup(soy.$$augmentData(opt_data, {actuals: opt_data.content.actuals_confirmed, star_contact_info: 1}), output);
  output.append('</table></div><div class="group-header">Cancelled Reservations (', soy.$$escapeHtml(opt_data.content.actuals_canceled.length), ')</div><div class="ressies"><table>');
  Nightloop.Templates.Direct.ReservationGroup(soy.$$augmentData(opt_data, {actuals: opt_data.content.actuals_canceled, star_contact_info: 1}), output);
  output.append('</table></div></div></div><script type="text/javascript">$(document).ready(function() {var page = Pmp.Client.Static.Page; page.AddLiveHandler(\'.received-button\', \'click\', function(event) {var actual_id = $(this).parents(\'.actual-row\').attr(\'actualid\'); var user_id = "', soy.$$escapeHtml(opt_data.content.user_id), '"; var venue_id = "', soy.$$escapeHtml(opt_data.content.venue_id), '"; var received_col = $(this).parents(\'.actual-row\').find(\'.status\'); var spinner = received_col.find(\'.received-spinner\'); var relativeUrl = "/direct/dining-reservations/" + venue_id + "/" + user_id + "/confirm/" + actual_id; var data = {\'is_received\' : \'y\'}; received_col.find(\'.received-button\').hide(); spinner.removeClass(\'no-display\'); Pmp.Client.LoadAjaxData(relativeUrl, data, true, function(data) {spinner.addClass(\'no-display\'); if (data.payload.success) {var html = Nightloop.Templates.Direct.ReceivedLink({\'actual\' : data.payload.actual, \'MEDIA_URL\' : "', soy.$$escapeHtml(opt_data.MEDIA_URL), '"}); received_col.html(html);} else {received_col.find(\'.received-button\').show();}}); return false;}); var linkID = \'.status-question-mark\'; page.AddLiveHandler(linkID, \'mouseenter\', function() {tooltip = $(this).parents(\'.status\').find(\'.status-help-tooltip\'); $(tooltip).show(); return true;}); page.AddLiveHandler(linkID, \'mouseleave\', function() {tooltip = $(this).parents(\'.status\').find(\'.status-help-tooltip\'); $(tooltip).hide(); return true;}); $(\'html\').css(\'background-color\',\'white\');});<\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Direct.ReservationGroup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<thead><tr><th class="date">DATE</th><th class="time">TIME</th><th class="guests"># GUESTS</th><th class="name">CLIENT</th><th class="phone">PHONE</th><th class="via">VIA</th><th class="notes">NOTES</th><th class="status"><div><div class="float-left">STATUS</div><div class="float-left status-question-mark"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/help-icon.png" /></div><div class="float-end"></div></div><div class="status-help-tooltip-container"><div class="status-help-tooltip">Click \'got it\' to let the concierge know that you\'ve transferred this reservation to your reservation book. A \'check mark\' means this reservation has already been transferred.</div></div></th></tr></thead><tbody>');
  var actualList13704 = opt_data.actuals;
  var actualListLen13704 = actualList13704.length;
  for (var actualIndex13704 = 0; actualIndex13704 < actualListLen13704; actualIndex13704++) {
    var actualData13704 = actualList13704[actualIndex13704];
    Nightloop.Templates.Direct.ReservationRow({actual: actualData13704, MEDIA_URL: opt_data.MEDIA_URL, phone_number: (opt_data.star_contact_info) ? soy.$$escapeHtml(actualData13704.booking_phone_number_starred) : soy.$$escapeHtml(actualData13704.booking_phone_number_formatted)}, output);
  }
  output.append('</tbody>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Direct.ReservationRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<tr class="actual-row" actualid=', soy.$$escapeHtml(opt_data.actual.id), '><td class="date">', opt_data.actual.date_formatted_short_with_dow_html, '</td><td class="time">', soy.$$escapeHtml(opt_data.actual.est_arrival_time_display), '</td><td class="guests">', soy.$$escapeHtml(opt_data.actual.total_guests), '</td><td class="name">', soy.$$escapeHtml(opt_data.actual.client_display_name), ' </td><td class="phone">', (opt_data.phone_number) ? soy.$$escapeHtml(opt_data.phone_number) : '', '</td><td class="via">', (opt_data.actual.via) ? soy.$$escapeHtml(opt_data.actual.via) : '&nbsp;', '</td><td class="notes">', (opt_data.actual.summary_notes_dining) ? soy.$$escapeHtml(opt_data.actual.summary_notes_dining) : '&nbsp;', '</td><td class="status">');
  Nightloop.Templates.Direct.ReceivedLink(opt_data, output);
  output.append('</td></tr>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Direct.ReceivedLink = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append((opt_data.actual.is_canceled) ? '<div class="cancel-received-button" title="This reservation was canceled by the client">Cancelled</div>' : (! opt_data.actual.is_received) ? '<div class="received-button" title="Clicking this lets the concierge know that you\'ve transferred this reservation to your reservation book.">got it</div>' : '<div class="cancel-received-button" title="You have seen this reservation and it has been transferred to your reservation book."><img class=\'reopen-check-img\' src=\'' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/green_check_mark.png\' /></div>', '<img class="no-display received-spinner" src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/spinner.gif" />');
  return opt_sb ? '' : output.toString();
};
