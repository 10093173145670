var Pmp = Pmp || {};
Pmp.Admin = Pmp.Admin || {};
Pmp.Admin.Venue = Pmp.Admin.Venue || {};
Pmp.Admin.Venue.Clients = Pmp.Admin.Venue.Clients || {};

Pmp.Admin.Venue.Clients.Import = {
	initialize : function() {
		this._base = Pmp.Main.Popup.Base;
		this._bindEventHandlers();
	},
	
	log : function(msg) {
		console.log("Pmp.Admin.Venue.Clients.Import: "+ msg);
	},
	
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	
	_bindEventHandlers : function() {
		this.debug("bindEventHandlers");
		
		var self = this;
		var clickSubmitFn = function(event) {self._clickSubmit(event, $(this));};
		var clickMakeChangesFn = function(event) {self._clickMakeChanges(event, $(this));};

		$('#clients-import-preview-btn').click(clickSubmitFn);
		$('#clients-import-confirm-btn').click(clickSubmitFn);
		$('#clients-import-makechanges-btn').click(clickMakeChangesFn);
		
		Pmp.Utils.InputOverlayPrompt('#clients-batch-input-container', '#clients-batch-input-prompt', true /* is_text_area */);
	},
	
	_clickSubmit : function(event, element) {
	    if (!$(element).hasClass('disabled')) {
	    	$(element).addClass('disabled pending');
			var formObj = $('#import-daily-clients-form');
			Pmp.Utils.LoadForm(event, formObj, '#main-popup', this._base.waitForLoadAndResize);
        }
	},
	
	_clickMakeChanges : function(event, element) {
		$('#clients-import-confirm-input').val('false');
		$('#clients-import-confirm-display').addClass('no-display');
		$('#clients-import-preview-display').removeClass('no-display');
		$('#clients-import-confirm-buttons').addClass('no-display');
		$('#clients-import-preview-buttons').removeClass('no-display');
		$.colorbox.resize();
	}
};