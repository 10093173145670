var Pmp = Pmp || {};
Pmp.Reservation = Pmp.Reservation || {};

Pmp.Reservation.ReservationFeedback = {
    initialize : function(venueId, entityId, entityType, venueColor, facebookLink, instagramLink, twitterLink,
        tripadvisorReviewLink, facebookReviewLink, googleReviewLink, yelpReviewLink, MEDIA_URL,
        reviewSiteLabels, feedbackPageShareToExternalReviewSiteHeader, feedbackPageCopyReviewLabel,
        feedbackPageCopiedLabel, feedbackPageCompleteHeader, feedbackPageCompleteSubheader, resPaylinkPageFollowUsLabel,
        referralCardSubject, referralCardBody, referralProgramEnabled, referralLink, imageBase,
        referralLinkFeedbackSuccessEmailEnabled) {
        this._venueId = venueId;
        this._entityId = entityId;
        this._entityType = entityType;
        this._venueColor = venueColor;
        this._facebookLink = (facebookLink && facebookLink !== 'None') ? facebookLink : null;
        this._instagramLink = (instagramLink && instagramLink !== 'None') ? instagramLink : null;
        this._twitterLink = (twitterLink && twitterLink !== 'None') ? twitterLink : null;
        this._tripadvisorReviewLink = (tripadvisorReviewLink && tripadvisorReviewLink !== 'None') ? tripadvisorReviewLink : null;
        this._facebookReviewLink = (facebookReviewLink && facebookReviewLink !== 'None') ? facebookReviewLink : null;
        this._googleReviewLink = (googleReviewLink && googleReviewLink !== 'None') ? googleReviewLink : null;
        this._yelpReviewLink = (yelpReviewLink && yelpReviewLink !== 'None') ? yelpReviewLink : null;
        this._MEDIA_URL = MEDIA_URL;
        this._reviewSiteLabels = reviewSiteLabels;
        this._feedbackPageShareToExternalReviewSiteHeader = feedbackPageShareToExternalReviewSiteHeader;
        this._feedbackPageCopyReviewLabel = feedbackPageCopyReviewLabel;
        this._feedbackPageCopiedLabel = feedbackPageCopiedLabel ? feedbackPageCopiedLabel : 'Copied';
        this._submittedFeedback = false;
        this._feedbackPageCompleteHeader = feedbackPageCompleteHeader;
        this._feedbackPageCompleteSubheader = feedbackPageCompleteSubheader;
        this._resPaylinkPageFollowUsLabel= resPaylinkPageFollowUsLabel;
        this._referralCardSubject = referralCardSubject;
        this._referralCardBody = referralCardBody;
        this._referralProgramEnabled = referralProgramEnabled;
        this._referralLink = referralLink;
        this._imageBase = imageBase;
        this._referralLinkFeedbackSuccessEmailEnabled = referralLinkFeedbackSuccessEmailEnabled;
        this._maxReviewCharCount = 500;
        this.bindClickRatingHandler();
        this.bindSubmitHandler();
    },

    displayNewStarRating: function(_ratingNum, ratingIdSelector) {
        var ratingNum = parseInt(_ratingNum, 10);
        for (var idNum = 1; idNum <= ratingNum; idNum++) {
            $(ratingIdSelector + idNum).removeClass('svr-icon--star-line').removeClass('svr-icon--star').addClass('svr-icon--star');
        }
        for (var idNum = ratingNum + 1; idNum <= 5; idNum++) {
            $(ratingIdSelector + idNum).removeClass('svr-icon--star').removeClass('svr-icon--star-line').addClass('svr-icon--star-line');
        }
    },

    bindClickRatingHandler: function() {
        var self = this;
        $('.star-rating').click(function() {
            var ratingAttribs = $(this).attr('id').split('-');
            var ratingCategory = ratingAttribs[0];
            var ratingNum = ratingAttribs[1];
            var ratingIdSelector = '#' + ratingCategory + '-';
            $('input[name=' + ratingCategory + ']').val(ratingNum);
            self.displayNewStarRating(ratingNum, ratingIdSelector);
        });
    },

    bindCopyReviewHandler: function() {
        var self = this;
        $('#copy-review-btn').click(function() {
            const tmpField = document.createElement('textarea');
            tmpField.innerText = $('#feedback-comments').val();
            document.body.appendChild(tmpField);
            tmpField.select();
            document.execCommand('copy');
            tmpField.remove();
            document.getElementById('copy-review-btn').innerHTML = self._feedbackPageCopiedLabel;
        });
    },

    submissionIsValid: function() {
        // Only required field is overall rating
        var feedbackRating = parseInt($('input[name=overall]').val(), 10)
        return !(isNaN(feedbackRating) || feedbackRating <= 0 || feedbackRating > 5);
    },

    getPlainText: function(text) {
        var referral_element = document.createElement('div')
        referral_element.innerHTML = text || ''
        return referral_element.innerText
    },
    submitFeedback: function(data) {
        var self = this;
        if(self._submittedFeedback) {
            return;
        }
        self._submittedFeedback = true;
        $("#feedback-rating-body").hide();
        $('#ajax-spinner').show();
        var feedback_data = {
            'feedback_type': self._entityType,
            'review_feedback': $('#feedback-comments').val(),
            'venue_specific_marketing': $("#venue_specific_marketing").is(':checked') ? true : null,
            'venue_group_marketing': $("#venue_group_marketing").is(':checked') ? true : null
        }
        $('.feedback-rating-vals').each(function() {
            var feedbackRating = $(this);
            var ratingIdentifier = feedbackRating.attr('name');
            var ratingVal = feedbackRating.val();
            feedback_data[ratingIdentifier] = ratingVal;
        });
        var feedback_recommend_entry = $('input[name=feedback_recommend]:checked').val();
        if (feedback_recommend_entry !== undefined) {
            feedback_data['feedback_recommend'] = feedback_recommend_entry;
        }
        var feedback_on_time = $('input[name=feedback_on_time]:checked').val();
        if (feedback_on_time !== undefined) {
            feedback_data['feedback_on_time'] = feedback_on_time;
        }
        var feedback_order_accurate = $('input[name=feedback_order_accurate]:checked').val();
        if (feedback_order_accurate !== undefined) {
            feedback_data['feedback_order_accurate'] = feedback_order_accurate;
        }
        var review_sites = {
            'tripadvisor': self._tripadvisorReviewLink,
            'facebook': self._facebookReviewLink,
            'google': self._googleReviewLink,
            'yelp': self._yelpReviewLink
        }
        var review_feedback = feedback_data['review_feedback'] ? feedback_data['review_feedback'].trim() : '';
        var truncated_feedback = review_feedback.length > self._maxReviewCharCount ? review_feedback.substring(0, self._maxReviewCharCount) + "..." : review_feedback;
        if (truncated_feedback) {
            truncated_feedback = '"' + truncated_feedback + '"';
        }
        $.ajax({
            url: '/direct/' + self._venueId + '/feedback/' + self._entityId + "/saveFeedback",
            type: 'POST',
            data: feedback_data,
            success : function (response) {
                if(!response) {
                    errorMsg = $('#error-msg');
                    errorMsg.html('Submission error. Please try again later.');
                    $("#feedback-rating-body").show();
                    errorMsg.show();
                    self._submittedFeedback = false;
                }
                else {
                    $("#feedback-rating-header").hide();
                    var confirmed_submission_page = Nightloop.Templates.Reservation.PostReservationFeedbackBit({
                        "venue_color" : self._venueColor,
                        "facebook_link": self._facebookLink,
                        "instagram_link": self._instagramLink,
                        "twitter_link": self._twitterLink,
                        "review_sites": review_sites,
                        "review_site_labels": self._reviewSiteLabels,
                        "MEDIA_URL": self._MEDIA_URL,
                        "overall_rating": feedback_data['overall'],
                        "truncated_feedback": truncated_feedback,
                        "feedback_page_share_to_external_review_site_header": self._feedbackPageShareToExternalReviewSiteHeader,
                        "feedback_page_copy_review_label": self._feedbackPageCopyReviewLabel,
                        "feedback_page_complete_header": self._feedbackPageCompleteHeader,
                        "feedback_page_complete_sub_header": self._feedbackPageCompleteSubheader,
                        "res_paylink_page_follow_us_label": self._resPaylinkPageFollowUsLabel,
                        "referral_card_subject": self.getPlainText(self._referralCardSubject),
                        "referral_card_body": self.getPlainText(self._referralCardBody),
                        "referral_program_enabled": self._referralProgramEnabled,
                        "referral_link": self._referralLink,
                        "image_base": self._imageBase,
                        "referral_link_feedback_success_email_enabled": self._referralLinkFeedbackSuccessEmailEnabled
                    });
                    $("#res_confirmation").append(confirmed_submission_page);
                    window.scrollTo(0, 0);
                    self.bindCopyReviewHandler();
                }
                $('#ajax-spinner').hide();
            }
        });
    },

    bindSubmitHandler: function() {
        var self = this;
        $('#feedback-submit-btn').click(function() {
            if (self.submissionIsValid()) {
                self.submitFeedback();
            } else {
                $('#error-msg').show();
            }
        });
    },
}
