
var Pmp = Pmp || {};
Pmp.Main = Pmp.Main || {};

Pmp.Main.Timeout = {
	initialize : function(checkIntervalMs, serverTimeoutMs, urlKeepAlive, sessionKeepaliveCookieName, urlLogout) {
		this.log("Initializing (checkIntervalMs="+ checkIntervalMs
						+", serverTimeoutMs="+ serverTimeoutMs
						+", urlKeepAlive="+ urlKeepAlive
						+", sessionKeepaliveCookieName="+ sessionKeepaliveCookieName
						+", urlLogout="+ urlLogout
						+")");
		this.initializeProperties(checkIntervalMs, serverTimeoutMs, urlKeepAlive, sessionKeepaliveCookieName, urlLogout);
		this.createListeners()
		this.startTimeoutCheck();
	},
	initializeProperties : function(checkIntervalMs, serverTimeoutMs, urlKeepAlive, sessionKeepaliveCookieName, urlLogout) {
		// How often to check for activity, in milliseconds
		this._checkIntervalMs = checkIntervalMs;
		// The URL to be pinged informing the server to keep the session alive longer
		this._urlKeepAlive = urlKeepAlive;
		// The max amount of time before a keepalive must be sent in the event of user activity
		this._serverTimeoutMs = serverTimeoutMs;
		// The Cookie name for the session identifier cookie
		this._sessionKeepaliveCookieName = sessionKeepaliveCookieName;
		// The URL to hit to log the user out
		this._urlLogout = urlLogout;
		
		var now = this.getTimeMs();
		this._lastServerCall = now;
		this._lastActivity = now;
	},
	log : function(msg) {
		console.log("Pmp.Main.Timeout: " + msg);
	},
	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},
	reportActivity : function() {
		this._lastActivity = this.getTimeMs();
		//console.debug('Reporting activity ' + this._lastActivity);
	},
	reportServerCall : function() {
		this._lastServerCall = this.getTimeMs();
		//console.debug('Reporting server call ' + this._lastServerCall);
	},
	shouldSendKeepalive : function() {
		return this.hasActivitySinceLastServerCall() && this.isLastServerCallCloseToServerTimeout();
	},
	hasActivitySinceLastServerCall : function() {
		return this._lastActivity > this._lastServerCall;
	},
	isLastServerCallCloseToServerTimeout : function() {
		var serverTimeout = this._lastServerCall + this._serverTimeoutMs;
		// Set the cutoff to 2x the check interval
		var serverTimeoutCutoff = serverTimeout - (2 * this._checkIntervalMs);
		return this.getTimeMs() > serverTimeoutCutoff;
	},
	getLastActivity : function() {
		return this._lastActivity;
	},
	
	/** Methods to set up keypress & click listeners and periodically check for activity **/
	createListeners : function() {
		var self = this; 
		var reportActivityCallbackFn = function() {self.reportActivity();};
		$(document).keypress(reportActivityCallbackFn);
		if ($(document).fullclick !== undefined) {
			// otherwise utils.noclickdelay will intervene with preventDefault and radio buttons won't work
			$(document).fullclick(reportActivityCallbackFn);
		} else {
			$(document).click(reportActivityCallbackFn);
		}
		// Also bind to all anchor tags explicitly so that this event isn't first suppressed by jquery tabs
		$('a').click(reportActivityCallbackFn);
		// Also bind to all multiselect divs explicitly so that this event isn't first suppressed by jquery multiSelects
		$('div.multiSelect').click(reportActivityCallbackFn);
	},
	
	startTimeoutCheck : function() {
		var self = this;
		var checkForActivityFn = function() {self.checkForActivity();};
		setInterval(checkForActivityFn, this._checkIntervalMs);
	},
	checkForActivity : function() {
		this.debug("checking for activity");
		if (this.isSessionCookieStillPresent() !== true) {
			this.redirectExpiredSession();
		} else if (this.shouldSendKeepalive()) {
			this.log('sending keep alive');
			this.sendKeepalive();
		}
	},
	isSessionCookieStillPresent : function() {
		return Pmp.Utils.GetCookieValue(this._sessionKeepaliveCookieName) !== undefined;
	},
	sendKeepalive : function() {
		var self = this;
		var redirectExpiredSessionFn = function() {self.redirectExpiredSession();};
		
		$.ajax({
			url : self._urlKeepAlive,
			error : redirectExpiredSessionFn
		});
		this.reportServerCall();
	},
	redirectExpiredSession : function() {
		window.location.href = this._urlLogout + '?rurl=' + window.location.pathname;
	},
	
	/** Time utility methods **/
	getTimeMs : function() {
		return (new Date()).getTime();
	},
	hasPassed : function(time) {
		return this.getTimeMs() > time;
	}
};
