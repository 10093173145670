// This file was automatically generated from admin.reindexclients.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ReindexPage = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ReindexPageBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ReindexPageBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="reinex-page"><div><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue_id), '/edit">&lt;&lt; back to venue edit</a></div><h3>Reindexing started for ', soy.$$escapeHtml(opt_data.content.venue_name), '</h3></div>');
  return opt_sb ? '' : output.toString();
};
