
var Pmp = Pmp || {};
Pmp.Common = Pmp.Common || {};
Pmp.Common.PhotoCopper = Pmp.Common.PhotoCropper || {};

Pmp.Common.PhotoCropper = {

  TYPES: {
    'client': 1,
    'account': 1
  },
  
  initialize: function(photo_type, options, type) {
    if (!this.TYPES[photo_type]) {
      throw 'You must initialize PhotoCropper with a valid photo_type.';
    }
    
    this._photo_type = photo_type;
    this._options = options;
    this._type = type;
    
    this._photoId = $('#photo-state').data('id');
    if (this._photoId) {
      $('#edit-image').removeClass('disabled');
    } else {
      $('#add-photo-teaser').show();
    }
    this._loadCropData();
    this.bindClickHandlers();
  },

  getCropPhotoUrl: function(target) {
    var url = this._options.base_url;
    if (this._photo_type === 'account') {
      return url + '/account/' + this._options.user_id + '/' + target;
    } else if (this._photo_type === 'client') {
      return url + '/clients/profile/' + this._options.client_id + '/' + target;
    }
  },
  
  bindClickHandlers: function() {
    $('#edit-image').click($.proxy(this._openImageEditor, this));
  },

  _initImageEditor : function() {
    var $image = $('#target-image');
    $image.attr('src', '/.h/download/' + this._photoId);
    $image.cropper({
      zoomable: true,
      aspectRatio: 1.0,
      data: this._cropData
    });
  },

  _openImageEditor: function(self) {
    var self = this;
    
    // Button is inactive until photoId exists
    if (!this._photoId) {
      return;
    }
    
    var $cancel = $('#edit-image-cancel');
    var $save = $('#edit-image-save');
    var $delete = $('#edit-image-delete');
    
    var destroyCropper = function() {
      $('#target-image').cropper('destroy');
    };

    var $popup = $('#edit-image-popup').bPopup({
      position: ['auto', 60],
      opacity: 0.5,
      modalClose: false,
      appendTo: '#page-account',
      onOpen: $.proxy(self._initImageEditor, self),
      onClose: destroyCropper
    });

    var closePopup = function() {
      $popup.close();
      $cancel.unbind('click');
      $save.unbind('click');
    };
    
    $cancel.click(closePopup);
    
    $save.click(function() {
      self._cropData = $('#target-image').cropper('getData');
      $.ajax({
        method: 'POST',
        url: self.getCropPhotoUrl('crop_photo'),
        data: {'crop_data':self._cropData, 'type':self._type},
        success: function(data) {
          $('#photo-img-src').attr('src', '/.h/download/' + data.medium);
          if (self._photo_type == 'account') {
            $('#account-scarab-photo').attr('src', '/.h/download/' + data.small);
          }
          closePopup();
        }
      });
    });

    $delete.click(function() {
      $.ajax({
        method: 'POST',
        url: self.getCropPhotoUrl('delete_photo'),
        data: { 'delete': true }, // protocols think it's a GET if there's no data
        success: function(data) {
          $('#photo-img-src').attr('src', Pmp.Settings.MEDIA_URL + 'images/no-photo.png');
          $('#add-photo-teaser').show();
          $('#edit-image').addClass('disabled');
          if (self._photo_type == 'account') {
            $('#account-scarab-photo').attr('src', Pmp.Settings.MEDIA_URL + 'images/default-person.png');
          }
          closePopup();
        }
      });
    });
  },
  
  _loadCropData: function() {
    var $state = $('#photo-state');
    var data = {};
    $.each(['x', 'y', 'width', 'height'], function(i, key) {
      var val = $state.data('crop-' + key);
      if (val !== undefined) {
        data[key] = val;
      }
    });
    this._cropData = data;
  },

  /** Called when a new image is uploaded. */
  onImageUpload: function(photo_key) {
    var photo_url = '/.h/download/' + photo_key;
    $('#add-photo-teaser').hide();
    $('#photo-img-src').attr('src', photo_url);
    if (this._photo_type == 'account') {
      $('#account-scarab-photo').attr('src', photo_url);
    }
    this._photoId = photo_key;
    this._cropData = {};
    // Now that a photo exists, allow cropping
    $('#edit-image').removeClass('disabled');
    this._openImageEditor();
  }
  
};
