var sr = sr || {};

sr.formatter = sr.formatter ||  {};

sr.formatter.getContrastYIQ = function (hexColor, darkColorDefault, lightColorDefault) {
    var darkColor = (darkColorDefault) ? darkColorDefault : 'black'
    var lightColor = (lightColorDefault) ? lightColorDefault : 'white'
    // * Source: http://24ways.org/2010/calculating-color-contrast
    var r   = parseInt(hexColor.substr(1, 2), 16),
        g   = parseInt(hexColor.substr(3, 2), 16),
        b   = parseInt(hexColor.substr(5, 2), 16),
        yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? darkColor : lightColor;
};
