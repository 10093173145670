// This file was automatically generated from admin.shortlinks.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ShortLinks = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ShortLinksBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ShortLinksBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-utilities-short-link">', (opt_data.success_message) ? '<div class="gold-message">' + soy.$$escapeHtml(opt_data.success_message) + '</div>' : '', (opt_data.error_message) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.error_message) + '</div>' : '', '<h2>Generate sevenrooms shortened links</h2><p><form method="post" action="/admin/short_links"><input id="sr-long-link" type="url" name="long-link" style="width: 250px;"/><br /><br /><input id="submit-long-link" type="submit" value="Submit" /></form>', (opt_data.short_link) ? '<br />Short link: <a style="cursor: pointer;" href="' + soy.$$escapeHtml(opt_data.short_link) + '" target="_blank">' + soy.$$escapeHtml(opt_data.short_link) + '</a>' : '', '</p></div>');
  return opt_sb ? '' : output.toString();
};
