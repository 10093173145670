// This file was automatically generated from admin.dedupclients.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.Clients = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Admin.DedupClients'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.DedupClients = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-clients"><p>Dedupe clients<hr/><p><input name="client-ids" type="text" placeholder="Comma-separated keys" /><br/><br/><input name="venue" type="text" placeholder="Venue Name" /><br/><br/><input name="process" type="submit" value="Merge!" /></p><script type="text/javascript">Pmp.Manager.Dedupe.initialize();<\/script></div>');
  return opt_sb ? '' : output.toString();
};
