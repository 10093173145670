// This file was automatically generated from widget.goldbutton.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.GoldButton = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div ', (opt_data.id) ? 'id="' + soy.$$escapeHtml(opt_data.id) + '" ' : '', 'class="float-left gold-button  ', (opt_data.size) ? soy.$$escapeHtml(opt_data.size) : 'medium', '  ', (opt_data.disabled && opt_data.disabled != 'false') ? 'disabled' : '', '  ', (opt_data.pending && opt_data.pending != 'false') ? 'pending' : '', '  ', (opt_data.classes) ? soy.$$escapeHtml(opt_data.classes) : '', '"  ', (opt_data.properties) ? opt_data.properties + ' ' : '', '><div class="gold-button-left"></div><span class="gold-button-text">', soy.$$escapeHtml(opt_data.text), '</span>', (opt_data.img) ? '<img class=\'' + ((opt_data.img_classes) ? soy.$$escapeHtml(opt_data.img_classes) : '') + '\' src="' + soy.$$escapeHtml(opt_data.img) + '" />' : '', '<div class="pending-div"></div><div class="gold-button-right"></div></div>');
  return opt_sb ? '' : output.toString();
};
