// This file was automatically generated from admin.instagram.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.Instagram = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.InstagramBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.InstagramBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="admin-instagram"><h1>Instagram</h1><form method="GET" target="_blank" action="/admin/instagram/get_handles_csv"><p>Get all IG handles for active dining venues</p><button type="submit">Get CSV</button></form></div>');
  return opt_sb ? '' : output.toString();
};
