// This file was automatically generated from admin.qrcode.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.QRCode = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.QRCodeBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.QRCodeBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="admin-qrcode"><h1>QR Code</h1></div><div style="display: inline-flex;width:100%;justify-content: space-between;align-items: center;"><div style="flex-basis: min-content;"><form action="/admin/qrcode" method="post" class="ajaxify"><textarea name="content">', soy.$$escapeHtml(opt_data.content.form.fields.content.value), '</textarea><input type="submit" value="Get QR" name="submit" /></form></div><div><img src="', soy.$$escapeHtml(opt_data.content.qr_code), '" alt="QR"/></div></div><div><textarea class="big-textarea">', soy.$$escapeHtml(opt_data.content.qr_code), '</textarea></div>');
  return opt_sb ? '' : output.toString();
};
