var Pmp = Pmp || {};
Pmp.Direct = Pmp.Direct || {};

Pmp.Direct.EmailCenter = {
  initialize: function(campaignId, clientId) {
    this.isTest = ('test' === campaignId);
    this.campaignId = campaignId;
    this.clientId = clientId;

    this.bindHandlers();
  },

  bindHandlers: function() {
    var self = this;

    $('#email-subscription-submit-btn').on('click', function() {
      self.submitForm();
    });
  },

  showError: function() {
    var $errorMsg = $('#error-msg');

    $errorMsg.html('Submission error. Please try again later.');
    $errorMsg.fadeIn();
  },

  successResult: function() {
    $('#email-subscription-header').html(
        'Email Preferences have been updated'
    );
    $('#marketing-opt-in-header, #email-subscriptions-body').fadeOut();
  },

  submitForm: function() {
    var self = this;
    if (self.isTest) {
      self.successResult();
      return;
    }

    $.ajax({
      url:
        '/direct/email-campaign-subscriptions/' +
        self.campaignId +
        '/' +
        self.clientId +
        '/unsubscribe',
      type: 'POST',
      success: function(response) {
        if (!response) {
          self.showError();
        } else {
          self.successResult();
        }
      },
      error: function() {
        self.showError();
      },
    });
  },
}
