// This file was automatically generated from direct.guestlist.cancel.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Consumer == 'undefined') { Nightloop.Templates.Consumer = {}; }


Nightloop.Templates.Consumer.GuestListCancel = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Consumer.GuestlistCancelLayout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Consumer.GuestListCancelBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Consumer.GuestlistCancelLayout = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="consumer"><div id="cancel-body">');
  Closure.Util.InsertTemplate(opt_data, output);
  output.append('</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Consumer.GuestlistCancellationHeader = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="gs-cancel-res-header" style="background-color:', (opt_data.content.alt_color) ? soy.$$escapeHtml(opt_data.content.alt_color) : '#000', '"><div id="gs-header-body" style="color:', (opt_data.content.alt_color == '#FFFFFF' || opt_data.content.alt_color == '#FFF') ? '#000' : (opt_data.content.alt_color == '#000000' || opt_data.content.alt_color == '#000') ? '#FFF' : '', ' !important">', soy.$$escapeHtml(opt_data.content.selected_language_strings.res_cancel_page_header_guestlist), '</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Consumer.GuestlistRemovedHeader = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="gs-cancel-res-header" style="background-color:', (opt_data.alt_color) ? soy.$$escapeHtml(opt_data.alt_color) : 'black', '"><div class="res-passed res-details gs-res-padding" style="', (opt_data.alt_color == '#FFFFFF' || opt_data.alt_color == '#FFF') ? 'color:#777777 !important;' : '', '">', soy.$$escapeHtml(opt_data.selected_language_strings.res_cancel_page_guestlist_removed_header), '</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Consumer.GuestlistPleaseCallHeader = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="gs-cancel-res-header" style="background-color:', (opt_data.alt_color) ? soy.$$escapeHtml(opt_data.alt_color) : 'black', '"><div class="res-passed res-details gs-res-padding" style="', (opt_data.alt_color == '#FFFFFF' || opt_data.alt_color == '#FFF') ? 'color:#777777 !important;' : '', '">', soy.$$escapeHtml(opt_data.selected_language_strings.res_cancel_page_unable_modify_guestlist_header), '</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Consumer.GuestListCancelBody = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-cancelreservation"><div id="reservation-details-display"><h1>', soy.$$escapeHtml(opt_data.content.selected_language_strings.res_cancel_page_res_details_label), '</h1><h4>', soy.$$escapeHtml(opt_data.content.selected_language_strings.res_cancel_page_guest_name), '</h4><p>', soy.$$escapeHtml(opt_data.content.client_name), '</p><h4>', soy.$$escapeHtml(opt_data.content.selected_language_strings.res_cancel_page_venue_name), '</h4><p>', soy.$$escapeHtml(opt_data.content.venue_name), '</p><h4>', soy.$$escapeHtml(opt_data.content.selected_language_strings.res_cancel_page_res_date), '</h4><p>', soy.$$escapeHtml(opt_data.content.date_formatted_display), '</p>', (opt_data.content.max_guests) ? '<h4>' + soy.$$escapeHtml(opt_data.content.selected_language_strings.res_cancel_page_party_size) + '</h4><p>' + soy.$$escapeHtml(opt_data.content.max_guests) + '</p>' : '', '<h4>', soy.$$escapeHtml(opt_data.content.selected_language_strings.res_cancel_page_reservation_id), '</h4><p>', soy.$$escapeHtml(opt_data.content.reference_code), '</p>', (! opt_data.content.is_canceled && opt_data.content.is_editable && ! opt_data.content.has_system_prepayment && ! opt_data.content.is_concierge) ? '<div class="actions"><p class="confirm" style="background-color: ' + soy.$$escapeHtml(opt_data.content.alt_color) + '"><a class="float-left" href="/direct/cancel-reservation/' + soy.$$escapeHtml(opt_data.content.actual_id) + '?confirm=true&lang=' + soy.$$escapeHtml(opt_data.content.language_code) + '" data-test="sr-cancel-res-link">' + soy.$$escapeHtml(opt_data.content.selected_language_strings.res_cancel_page_remove_button_guestlist) + '</a></p></div>' : '', '</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Consumer.GuestListCancelBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  if (opt_data.content.has_passed) {
    Nightloop.Templates.Consumer.GuestlistRemovedHeader({alt_color: opt_data.content.alt_color, selected_language_strings: opt_data.content.selected_language_strings}, output);
  } else if (opt_data.content.is_canceled) {
    Nightloop.Templates.Consumer.GuestlistRemovedHeader({alt_color: opt_data.content.alt_color, selected_language_strings: opt_data.content.selected_language_strings}, output);
  } else if (! opt_data.content.is_editable || opt_data.content.has_system_prepayment || opt_data.content.is_concierge) {
    Nightloop.Templates.Consumer.GuestlistPleaseCallHeader({alt_color: opt_data.content.alt_color, selected_language_strings: opt_data.content.selected_language_strings}, output);
    Nightloop.Templates.Consumer.GuestListCancelBody(opt_data, output);
  } else {
    Nightloop.Templates.Consumer.GuestlistCancellationHeader(opt_data, output);
    Nightloop.Templates.Consumer.GuestListCancelBody(opt_data, output);
  }
  return opt_sb ? '' : output.toString();
};
