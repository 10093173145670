// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList15507 = opt_data.frozenFollowers;
  var followerListLen15507 = followerList15507.length;
  for (var followerIndex15507 = 0; followerIndex15507 < followerListLen15507; followerIndex15507++) {
    var followerData15507 = followerList15507[followerIndex15507];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData15507.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData15507.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList15515 = opt_data.followers;
  var followerListLen15515 = followerList15515.length;
  for (var followerIndex15515 = 0; followerIndex15515 < followerListLen15515; followerIndex15515++) {
    var followerData15515 = followerList15515[followerIndex15515];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData15515.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData15515.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData15515.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData15515.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
