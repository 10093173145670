// This file was automatically generated from admin.interactivefloorplanimages.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenueInteractiveFloorplanImages = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.VenueInteractiveFloorplanImagesBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueInteractiveFloorplanImagesBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/commons.js']), '" crossorigin="anonymous"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/core.js']), '" crossorigin="anonymous"><\/script><script type="text/javascript" charset="UTF-8" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/admin.js']), '"><\/script><div id="venue-interactive-floorplan-images" /><script>window.SvrManager.InteractiveFloorplanImages.render(\'venue-interactive-floorplan-images\', \'', soy.$$escapeJsString(opt_data.content.venue.id), '\', \'', soy.$$escapeJsString(opt_data.content.venue.name), '\', \'', soy.$$escapeJsString(opt_data.content.venue.url_key), '\')<\/script><script type="text/javascript">\n          $(document).ready(function() {\n            $(\'#content-container\').css({\n                \'background\': \'#F7F7F7\',\n            });\n            $(\'#content\').css({\n                \'width\': \'unset\',\n                \'border\': \'none\',\n                \'background\': \'none\',\n                \'padding\': \'0\',\n            });\n          });\n        <\/script>');
  return opt_sb ? '' : output.toString();
};
