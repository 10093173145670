var Pmp = Pmp || {};
Pmp.Common = Pmp.Common || {};

Pmp.Common.TagCache = {
  initialize : function(tag_cache_url) {
    this._tag_cache_url = tag_cache_url;
    this._tag_groups = [];
    this._tags_by_id = {};
  },

  refresh : function(call_back, no_render) {
    var self = this;
    var url = this._tag_cache_url;
    var the_call_back = call_back;
    Pmp.Client.AsyncGet(url, function(data) {
      var content = data.payload.content;
      self._tag_groups = content.tag_groups;

      var map = {};
      for (var i = 0; i < self._tag_groups.length; ++i) {
        map[self._tag_groups[i].id] = self._tag_groups[i];
      }
      self._tags_by_id = map;

      if (!no_render) {
        self.renderTags();
      }
      if (the_call_back) {
        // Pass the content to use the fetched data
        the_call_back(content);
      }
    });
  },

  search : function(search_str) {
    search_str = search_str.toLowerCase();
    results = [];
    for (var i=0; i < this._tag_groups.length; i++) {
      var tag_group = this._tag_groups[i];
      for (var j=0; j < tag_group.tags.length; j++) {
        var tag_name = tag_group.tags[j];
        var tag_name_display = tag_group.tag_name_displays[tag_name] || tag_name;
        if (this._startsWith(search_str.toLowerCase(), tag_name_display.toLowerCase())) {
          results.push({
            'tag_name' : tag_name,
            'tag_group_name' : tag_group.name,
            'tag_group_id' : tag_group.id,
            'privacy' : tag_group.privacy,
            'color_hex' : tag_group.color_hex
          });
        }
      }
    }
    return results;
  },

  renderTags : function() {
    var self = this;

    $('.tag-item').each(function(e,obj){
      var tag_group_id = $(obj).find('.tag-group-id').val();
      if (tag_group_id == '') {
        tag_group_id = $(obj).find('.tag-group-id').html();
      }
      var tag_group_obj = self._findTagGroupById(tag_group_id);
      // some tags can be removed from tag list but still be on other objects
      if (tag_group_obj) {
        var bg_color_hex = tag_group_obj.color_hex;
        $(obj).find('.tag-item-color').css({'background-color':bg_color_hex});
        $(obj).parents('.tag-item-container').attr('sort_order_group', tag_group_obj.sort_order);
        $(obj).parents('.tags-group').attr('sort_order_group', tag_group_obj.sort_order);
        $(obj).parents('.tags-group').attr('tag_group_id', tag_group_id);
      }
    });
  },

  sortTags : function (container_id) {
    $('.tag-item-container', container_id).tsort({order:'asc',attr:'sort_order_group'}, {data:'int'});
  },

  sortTagsGroup : function (container_id) {
    $('.tags-group', container_id).tsort({order:'asc',attr:'sort_order_group'}, {data:'int'});
  },

  renderTagTable : function(container_id) {
    var html = Nightloop.Templates.Widget.TagTable({
      'tag_groups' : this._tag_groups
    });
    $(container_id).html(html);
  },

  groupNameFromId : function(id) {
    var group = this._findTagGroupById(id);
    if (group) {
      return group.name;
    }
    return '';
  },

  groupColorFromId : function(id) {
    var group = this._findTagGroupById(id);
    if (group) {
      return group.color_hex;
    }
    return '#ffffff';
  },

  _findTagGroupById : function(id) {
    for (var i=0; i < this._tag_groups.length; i++) {
      var tag_group = this._tag_groups[i];
      if (tag_group.id == id) {
        return tag_group;
      }
    }
    return null;
  },

  _startsWith : function(search_str, full_str) {
    return(full_str.indexOf(search_str) == 0);
  },

  initializeTagPicker : function(input_id, results_id, scope_id, input_prefix, insert_tags_id, insert_tags_container_id) {
    var tags_input = $(input_id, scope_id);
    tags_input.keyup(function(ev) {
          if ((ev.which == 37) || (ev.which == 38)) {
              // left/up
            Pmp.Common.TagCache._onFindTagsPressUp(scope_id);
              ev.preventDefault();
          } else if ((ev.which == 39) || (ev.which == 40)){
            // right/down
            Pmp.Common.TagCache._onFindTagsPressDown(scope_id);
            ev.preventDefault();
          } else if (ev.which == 13) {
            // enter
            Pmp.Common.TagCache._onFindTagsPressEnter(scope_id);
            ev.preventDefault();
          } else if (ev.which == 8) {
            Pmp.Common.TagCache._onFindTagsInputKeypress(this, results_id, scope_id);
            if (tags_input.val().length == 0) {
              var lastTag = $('.picker-tags-container', scope_id).find('.tag-item-container').last();
              if (lastTag.length) {
                lastTag.fadeOut('slow').remove();
              }
            }
          } else {
            Pmp.Common.TagCache._onFindTagsInputKeypress(this, results_id, scope_id);
          }
      });
    //tags_input.blur(function() { $(results_id).hide(); });
    tags_input.click(function() { Pmp.Common.TagCache._onFindTagsInputKeypress(this, results_id, scope_id); });

    var page = Pmp.Client.Static.Page;
    page.AddLiveHandler(scope_id + ' .tag-item-container', 'click', function(event) {
      Pmp.Common.TagCache._onAddTagToSearch(this, input_id, scope_id, input_prefix, insert_tags_id, insert_tags_container_id);
      $(results_id).hide();
    });
  },

  _onFindTagsPressUp : function(scope_id) {
    var selected = $('.tag-table-container .tag-item-container.selected', scope_id);
    var all_visible = $('.tag-table-container .tag-item-container', scope_id).filter(':visible');
    var idx = all_visible.index(selected);
    if ((idx-1) >= 0) {
      var new_selected = all_visible.get(idx-1);
      if ($(new_selected).length > 0) {
        $(new_selected).addClass('selected');
        $(selected).removeClass('selected');
      }
    }
  },

  _onFindTagsPressDown : function(scope_id) {
    var selected = $('.tag-table-container .tag-item-container.selected', scope_id);
    var all_visible = $('.tag-table-container .tag-item-container', scope_id).filter(':visible');
    var idx = all_visible.index(selected);
    if ((idx+1) < all_visible.length) {
      var new_selected = all_visible.get(idx+1);
      if ($(new_selected).length > 0) {
        $(new_selected).addClass('selected');
        $(selected).removeClass('selected');
      }
    }
  },

  _onFindTagsPressEnter : function(scope_id) {
    $('.tag-table-container .tag-item-container.selected', scope_id).mousedown();
  },

  _onFindTagsInputKeypress : function(el, results_id, scope_id) {
    var searchStr = $(el).val();
    var resultsDom = $(results_id, scope_id);
    var results = Pmp.Common.TagCache.search(searchStr);

    if (!searchStr.length) {
      $('.tag-table-group', scope_id + ' .tag-table-container').show();
      $('.tag-item-container', scope_id + ' .tag-table-container').show();
    } else {
      $('.tag-table-group', scope_id + ' .tag-table-container').hide();
      $('.tag-item-container', scope_id + ' .tag-table-container').hide();
    }
    for (var i=0;i<results.length;i++) {
      var tag_name = results[i].tag_name;
      var tag_group_name = results[i].tag_group_name;
      var tag_group_id = results[i].tag_group_id;
      var privacy = results[i].privacy;
      var hash = privacy + '##' + tag_group_id + '##' + tag_name;
      $('.tag-group-id-name-hash', scope_id + ' .tag-table-container').each(function(idx, el) {
        if ($(el).text() == hash) {
          $(el).parents('.tag-table-group').show();
          $(el).parents('.tag-item-container').show();
          return false;
        }
      })
    }
    var tags = $('.tag-item-container', scope_id + ' .tag-table-container');
    tags.removeClass('selected');
    if (!resultsDom.is(':visible')) {
      resultsDom.show();
    }
    tags.filter(':visible').first().addClass('selected');
  },

  _onAddTagToSearch : function(el, input_id, scope_id, input_prefix, insert_tags_id, insert_tags_container_id) {

    var tag_group_id = $(el).find('.tag-group-id').html();

    var privacy = $(el).find('.privacy').html();
    var tag_group_name = Pmp.Common.TagCache.groupNameFromId(tag_group_id);
    var tag_group_color = Pmp.Common.TagCache.groupColorFromId(tag_group_id);
    var tag_name = $(el).find('.tag-name-input').text();
    var tag_name_display = $(el).find('.tag-name').text();

    var insert_container = (insert_tags_container_id === undefined) ? $('.picker-tags-container', scope_id) : $('#'+insert_tags_container_id);

    if (Pmp.Common.TagCache._tagAlreadyExists(tag_group_id, tag_name, insert_container)) {
      return;
    }

    var html = Nightloop.Templates.Widget.Tag({
      'tag_name' : tag_name,
      'tag_name_display' : tag_name_display,
      'tag_group_id' : tag_group_id,
      'tag_group_name' : tag_group_name,
      'tag_color' : tag_group_color,
      'is_private' : privacy.toUpperCase() == 'PRIVATE',
      'close_func' : 'Pmp.Common.TagCache._onCloseTag(this)',
      'full_hash_only' : true,
      'full_hash_prefix' : input_prefix
    });

    var insert_el = (insert_tags_id === undefined) ? $('.add-picker-tags-here', scope_id) : $('#' + insert_tags_id);

    $(html).insertBefore(insert_el);
    $(input_id).val('');

    if (insert_tags_id === undefined) {
      $(input_id).css({'width':'75px'}); //wrapping of input box prevents selection, so make shorter
    }

    $('.tag-table-group', scope_id + ' .tag-table-container').show();
    $('.tag-item-container', scope_id + ' .tag-table-container').show();

  },

  _tagAlreadyExists : function(tag_group_id, new_tag_name, tag_group_obj) {
    var tag_map = {};
    $(tag_group_obj).find('.tag-name-input').each(function(e,div){
      var tag_name = $(div).text();
      var existing_tag_group_id = $(div).parents('.tag-item-container').find('span.tag-group-id').html();
      tag_map[tag_name.toLowerCase()+existing_tag_group_id] = true;
    });
    return ((new_tag_name.toLowerCase()+tag_group_id) in tag_map);
  },

  _onCloseTag : function(el) {
    $(el).parents('.tag-item-container').remove();
  }

};
