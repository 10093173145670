// This file was automatically generated from admin.toastvenues.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.Toast = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ToastBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ToastBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="admin-toastvenues"><h1>Toast Venues</h1></div><table style="width:100%"><tr><th style="font-weight:900,color:black">Restaurant Name</th><th style="font-weight:900,color:black">Restaurant GUID</th><th style="font-weight:900,color:black">Location Name</th><th style="font-weight:900,color:black">Created Date</th><th style="font-weight:900,color:black">Modified Date</th></tr>');
  var vList1704 = opt_data.venue_list;
  var vListLen1704 = vList1704.length;
  for (var vIndex1704 = 0; vIndex1704 < vListLen1704; vIndex1704++) {
    var vData1704 = vList1704[vIndex1704];
    output.append('<tr><th style="font-weight:500">', soy.$$escapeHtml(vData1704['venue_name']), '</th><th style="font-weight:500"><span class="guid-text">', soy.$$escapeHtml(vData1704['venue_guid']), '</span><button class="copy-guid" style="margin-left:15px">Copy</button></th><th style="font-weight:500">', soy.$$escapeHtml(vData1704['venue_location_name']), '</th><th style="font-weight:500">', soy.$$escapeHtml(vData1704['venue_created_date']), '</th><th style="font-weight:500">', soy.$$escapeHtml(vData1704['venue_modified_date']), '</th></tr>');
  }
  output.append('</table><script type="text/javascript">$(\'.copy-guid\').click(function() {var el = document.createElement(\'textarea\'); el.value = $(this).parent().find(\'.guid-text\').text(); document.body.appendChild(el); el.select(); document.execCommand(\'copy\'); document.body.removeChild(el);})<\/script>');
  return opt_sb ? '' : output.toString();
};
