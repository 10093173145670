// This file was automatically generated from admin.user.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.UserList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.UserListBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.UserListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="userlist-page"><div class="content-header">', (opt_data.content.entity_name) ? soy.$$escapeHtml(opt_data.content.entity_name) + ' ' : '', 'Users</div><br/><div class="right vpadding"><a id="user-add-new-link" class="ajaxify" href="/admin/user/edit/?user_domain=', soy.$$escapeHtml(opt_data.content.user_domain), '">+ add user</a></div>');
  if (opt_data.content.venue_users.length > 0) {
    output.append('<div class="list-header">Venue Users<div class="float-right"><form class="ajaxify" method="get" action="/admin/user/venue/list"><input name="search_query" value="', soy.$$escapeHtml(opt_data.content.search_query), '"/> <input type="submit" value="search" /></form></div></div>');
    var userList1975 = opt_data.content.venue_users;
    var userListLen1975 = userList1975.length;
    for (var userIndex1975 = 0; userIndex1975 < userListLen1975; userIndex1975++) {
      var userData1975 = userList1975[userIndex1975];
      Nightloop.Templates.Admin.UserRecord(soy.$$augmentData(opt_data, {user: userData1975}), output);
    }
    output.append((opt_data.content.next_cursor) ? '<div class="right vpadding"><a id="more-venue-users-link" class="ajaxify" href="/admin/user/venue/list?' + ((opt_data.content.search_query) ? 'search_query=' + soy.$$escapeHtml(opt_data.content.search_query) + '&' : '') + 'cursor=' + soy.$$escapeHtml(opt_data.content.next_cursor) + '"> >> next 30 venue users </a></div>' : '');
  }
  if (opt_data.content.venuegroup_users.length > 0) {
    output.append('<div class="list-header">Venue Group Users<div class="float-right"><form class="ajaxify" method="get" action="/admin/user/venue/list"><input name="search_query" value="', soy.$$escapeHtml(opt_data.content.search_query), '"/> <input type="submit" value="search" /></form></div></div>');
    var userList1995 = opt_data.content.venuegroup_users;
    var userListLen1995 = userList1995.length;
    for (var userIndex1995 = 0; userIndex1995 < userListLen1995; userIndex1995++) {
      var userData1995 = userList1995[userIndex1995];
      Nightloop.Templates.Admin.UserRecord(soy.$$augmentData(opt_data, {user: userData1995}), output);
    }
  }
  if (opt_data.content.concierge_users.length > 0) {
    output.append('<div class="list-header">Concierge Users<div class="float-right"><form class="ajaxify" method="get" action="/admin/user/concierge/list"><input name="search_query" value="', soy.$$escapeHtml(opt_data.content.search_query), '"/> <input type="submit" value="search" /></form></div></div>');
    var userList2004 = opt_data.content.concierge_users;
    var userListLen2004 = userList2004.length;
    for (var userIndex2004 = 0; userIndex2004 < userListLen2004; userIndex2004++) {
      var userData2004 = userList2004[userIndex2004];
      Nightloop.Templates.Admin.UserRecord(soy.$$augmentData(opt_data, {user: userData2004}), output);
    }
    output.append((opt_data.content.next_cursor) ? '<div class="right vpadding"><a id="more-concierge-users-link" class="ajaxify" href="/admin/user/concierge/list?' + ((opt_data.content.search_query) ? 'search_query=' + soy.$$escapeHtml(opt_data.content.search_query) + '&' : '') + 'cursor=' + soy.$$escapeHtml(opt_data.content.next_cursor) + '"> >> next 30 concierge users </a></div>' : '');
  }
  if (opt_data.content.member_users.length > 0) {
    output.append('<div class="list-header">Member Users<div class="float-right"><form class="ajaxify" method="get" action="/admin/user/member/list"><input name="search_query" value="', soy.$$escapeHtml(opt_data.content.search_query), '"/> <input type="submit" value="search" /></form></div></div>');
    var userList2024 = opt_data.content.member_users;
    var userListLen2024 = userList2024.length;
    for (var userIndex2024 = 0; userIndex2024 < userListLen2024; userIndex2024++) {
      var userData2024 = userList2024[userIndex2024];
      Nightloop.Templates.Admin.UserRecord(soy.$$augmentData(opt_data, {user: userData2024}), output);
    }
    output.append((opt_data.content.next_cursor) ? '<div class="right vpadding"><a id="more-member-users-link" class="ajaxify" href="/admin/user/member/list?' + ((opt_data.content.search_query) ? 'search_query=' + soy.$$escapeHtml(opt_data.content.search_query) + '&' : '') + 'cursor=' + soy.$$escapeHtml(opt_data.content.next_cursor) + '"> >> next 30 member users </a></div>' : '');
  }
  output.append('<div class="right vpadding"><a class="ajaxify" href="/admin/user/edit/?user_domain=', soy.$$escapeHtml(opt_data.content.user_domain), '">+ add user</a></div></div><script type="text/javascript">\n        $(\'.once\').one(\'submit\', function() {\n            $(this).find(\'input[type="submit"]\').attr(\'disabled\',\'disabled\');\n        });\n        <\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.UserRecord = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="container user-record ', (opt_data.user.is_superhero) ? 'super-user-record' : '', ' ', (! opt_data.user.is_active) ? 'inactive-user-record' : '', '"><a class="ajaxify float-right user-edit-link" href="/admin/user/edit/', soy.$$escapeHtml(opt_data.user.id), '">[edit user]</a><span class="user-full-name"><b>', soy.$$escapeHtml(opt_data.user.last_name), ', ', soy.$$escapeHtml(opt_data.user.first_name), '</b></span> - ', soy.$$escapeHtml(opt_data.user.email), '<br/>', (opt_data.user.is_superhero) ? '  *** SUPERHERO *** <br/>' : '', (! opt_data.user.is_active) ? '  *** INACTIVE *** <br/>' : '');
  if (opt_data.user.user_domain == 'VENUE') {
    var venue_group_userList2068 = opt_data.user.venue_group_users;
    var venue_group_userListLen2068 = venue_group_userList2068.length;
    for (var venue_group_userIndex2068 = 0; venue_group_userIndex2068 < venue_group_userListLen2068; venue_group_userIndex2068++) {
      var venue_group_userData2068 = venue_group_userList2068[venue_group_userIndex2068];
      output.append('<div class="venue-access-row"><span class="venue-access-privilege">Group User</span> @ <span class="venue-access-name">', soy.$$escapeHtml(venue_group_userData2068.venue_group_name), '</span> <a class="ajaxify" href="/admin/venuegroupuser/edit/', soy.$$escapeHtml(venue_group_userData2068.id), '">[edit access]</a> <a class="ajaxify delete-venue-access-link" href="/admin/venuegroupuser/delete/', soy.$$escapeHtml(venue_group_userData2068.id), '">[delete access]</a> <a class="ajaxify" href="/admin/user/entity/', soy.$$escapeHtml(venue_group_userData2068.venue_group_id), '/list">[', soy.$$escapeHtml(venue_group_userData2068.venue_group_name), ' users]</a> <a class="ajaxify" href="/admin/venuegroup/', soy.$$escapeHtml(venue_group_userData2068.venue_group_id), '/edit">[', soy.$$escapeHtml(venue_group_userData2068.venue_group_name), ' settings]</a></div>');
    }
    if (opt_data.user.venue_group_user_set.length > 0) {
      var venue_userList2087 = opt_data.user.venue_group_user_set;
      var venue_userListLen2087 = venue_userList2087.length;
      for (var venue_userIndex2087 = 0; venue_userIndex2087 < venue_userListLen2087; venue_userIndex2087++) {
        var venue_userData2087 = venue_userList2087[venue_userIndex2087];
        output.append('<div class="venue-access-row"><span class="venue-access-privilege">', soy.$$escapeHtml(venue_userData2087.privilege_level_display), '</span> @ <span class="venue-access-name">', soy.$$escapeHtml(venue_userData2087.venue_name), '</span> <a class="ajaxify" href="/admin/venueuser/edit/', soy.$$escapeHtml(venue_userData2087.id), '">[edit access]</a> <a class="ajaxify delete-venue-access-link" href="/admin/venueuser/delete/', soy.$$escapeHtml(venue_userData2087.id), '">[delete access]</a> <a class="ajaxify" href="/admin/user/entity/', soy.$$escapeHtml(venue_userData2087.venue_id), '/list">[', soy.$$escapeHtml(venue_userData2087.venue_name), ' users]</a> <a class="ajaxify" href="/admin/venue/', soy.$$escapeHtml(venue_userData2087.venue_id), '/edit">[', soy.$$escapeHtml(venue_userData2087.venue_name), ' settings]</a></div>');
      }
    } else {
      var venue_userList2107 = opt_data.user.venue_user_set;
      var venue_userListLen2107 = venue_userList2107.length;
      for (var venue_userIndex2107 = 0; venue_userIndex2107 < venue_userListLen2107; venue_userIndex2107++) {
        var venue_userData2107 = venue_userList2107[venue_userIndex2107];
        output.append('<div class="venue-access-row"><span class="venue-access-privilege">', soy.$$escapeHtml(venue_userData2107.privilege_level_display), '</span> @ <span class="venue-access-name">', soy.$$escapeHtml(venue_userData2107.venue_name), '</span> <a class="ajaxify" href="/admin/venueuser/edit/', soy.$$escapeHtml(venue_userData2107.id), '">[edit access]</a> <a class="ajaxify delete-venue-access-link" href="/admin/venueuser/delete/', soy.$$escapeHtml(venue_userData2107.id), '">[delete access]</a> <a class="ajaxify" href="/admin/user/entity/', soy.$$escapeHtml(venue_userData2107.venue_id), '/list">[', soy.$$escapeHtml(venue_userData2107.venue_name), ' users]</a> <a class="ajaxify" href="/admin/venue/', soy.$$escapeHtml(venue_userData2107.venue_id), '/edit">[', soy.$$escapeHtml(venue_userData2107.venue_name), ' settings]</a></div>');
      }
      output.append((! opt_data.content.suppress_add_access) ? '<div><a class="ajaxify create-venue-group-access ' + ((opt_data.user.is_unassigned) ? 'unassigned-user-link' : '') + '" href="/admin/venuegroupuser/create/' + soy.$$escapeHtml(opt_data.user.id) + '">+ new venue group access</a></div><div><a class="ajaxify create-venue-access ' + ((opt_data.user.is_unassigned) ? 'unassigned-user-link' : '') + '" href="/admin/venueuser/create/' + soy.$$escapeHtml(opt_data.user.id) + '">+ new venue access</a></div>' : '');
    }
  } else if (opt_data.user.user_domain == 'CONCIERGE') {
    var concierge_userList2142 = opt_data.user.concierge_user_set;
    var concierge_userListLen2142 = concierge_userList2142.length;
    for (var concierge_userIndex2142 = 0; concierge_userIndex2142 < concierge_userListLen2142; concierge_userIndex2142++) {
      var concierge_userData2142 = concierge_userList2142[concierge_userIndex2142];
      output.append('<div class="concierge-access-row"><span class="concierge-access-privilege">', soy.$$escapeHtml(concierge_userData2142.privilege_level_display), '</span> @ <span class="concierge-access-name">', soy.$$escapeHtml(concierge_userData2142.concierge_name), '</span> <a class="ajaxify" href="/admin/conciergeuser/edit/', soy.$$escapeHtml(concierge_userData2142.id), '">[edit access]</a> <a class="ajaxify delete-concierge-access-link" href="/admin/conciergeuser/delete/', soy.$$escapeHtml(concierge_userData2142.id), '">[delete access]</a> <a class="ajaxify" href="/admin/user/entity/', soy.$$escapeHtml(concierge_userData2142.concierge_id), '/list">[', soy.$$escapeHtml(concierge_userData2142.concierge_name), ' users]</a></div>');
    }
    output.append((! opt_data.content.suppress_add_access) ? '<a class="ajaxify create-concierge-access ' + ((opt_data.user.is_unassigned) ? 'unassigned-user-link' : '') + '" href="/admin/conciergeuser/create/' + soy.$$escapeHtml(opt_data.user.id) + '">+ new concierge access</a>' : '');
  }
  output.append('</div>');
  return opt_sb ? '' : output.toString();
};
