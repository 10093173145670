// This file was automatically generated from admin.venue.paymentsdebugger.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenuePaymentsDebugger = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.PaymentsDebuggerBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.PaymentsDebuggerBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/commons.js']), '" crossorigin="anonymous"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/core.js']), '" crossorigin="anonymous"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/admin.js']), '"><\/script><div><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/edit">&lt;&lt; back to venue edit</a></div><div id="payments-debugger">payments-debugger ', soy.$$escapeHtml(opt_data.content.venue.id), ' </div><script>window.SvrManager.PaymentsDebugger.render(\'payments-debugger\', \'', soy.$$escapeHtml(opt_data.content.venue.id), '\')<\/script>');
  return opt_sb ? '' : output.toString();
};
