// This file was automatically generated from direct.componentlib.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.ComponentLib == 'undefined') { Nightloop.Templates.ComponentLib = {}; }


Nightloop.Templates.ComponentLib.Main = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<head><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1"><meta name="storybook-version" content="3.2.3"><meta content="IE=edge" http-equiv="X-UA-Compatible" /><title>Storybook</title><link rel="stylesheet" type="text/css" href="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['css/fonts.css']), '"><script async src="https://use.fortawesome.com/a2a676c1.js"><\/script><script async src="https://use.fortawesome.com/5c2efbbd.js"><\/script>\n  <style>\n    /*\n      When resizing panels, the drag event breaks if the cursor\n      moves over the iframe. Add the \'dragging\' class to the body\n      at drag start and remove it when the drag ends.\n     */\n    .dragging iframe {\n      pointer-events: none;\n    }\n    /* Styling the fuzzy search box placeholders */\n    .searchBox::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n      color: #ddd;\n      font-size: 16px;\n    }\n    .searchBox::-moz-placeholder { /* Firefox 19+ */\n      color: #ddd;\n      font-size: 16px;\n    }\n    .searchBox:focus{\n      border-color: #EEE !important;\n    }\n    .btn:hover{\n      background-color: #eee\n    }\n    /* Remove blue outline defined by the user argent*/\n    :focus {\n      outline: none !important;\n    }\n  </style>\n  <meta name="robots" content="noindex" /></head><body style="margin: 0;"><div id="root"></div><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/component-lib/manager.js']), '"  crossorigin="anonymous" defer><\/script></body>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.ComponentLib.Iframe = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<head><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1"><link rel="stylesheet" type="text/css" href="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['css/fonts.css']), '"><script src="https://use.fortawesome.com/a2a676c1.js"><\/script><script src="https://use.fortawesome.com/5c2efbbd.js"><\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['frontend/fortawesome']), '"><\/script> <!-- new VmsIcons font from ui-kit -->\n  <script>\n    if (window.parent !== window) {\n      window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = window.parent.__REACT_DEVTOOLS_GLOBAL_HOOK__;\n    }\n  <\/script>\n  <script>window.MEDIA_URL = \'', soy.$$escapeHtml(opt_data.MEDIA_URL), '\';<\/script><title>Storybook</title><meta name="robots" content="noindex" /></head><body><div id="root"></div><div id="error-display"></div><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/component-lib/preview.js']), '" crossorigin="anonymous" defer><\/script></body>');
  return opt_sb ? '' : output.toString();
};
