// This file was automatically generated from direct.viewlastsentemail.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Direct == 'undefined') { Nightloop.Templates.Direct = {}; }


Nightloop.Templates.Direct.ViewLastSentEmail = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Direct.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Direct.ViewLastSentEmailBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Direct.ViewLastSentEmailBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-viewlastsentemail">', opt_data.content.body, '</div>');
  return opt_sb ? '' : output.toString();
};
