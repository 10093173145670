var Pmp = Pmp || {};
Pmp.Admin = Pmp.Admin || {};
Pmp.Admin.Venue = Pmp.Admin.Venue || {};

Pmp.Admin.Venue.Edit = {
  to_radio_value: {
    true: '1',
    false: '0',
    null: '',
  },
  from_radio_value: {
    '1': true,
    '0': false,
    '': null,
  },
  get_value_func: {
    'checkbox': function (input) {
      return input.checked;
    },
    'radio': function (input) {
      return input.value in this.from_radio_value ? this.from_radio_value[input.value] : input.value;
    },
    'select-one': function (input) {
      return input.value;
    },
    'select-multiple': function (input) {
      return $(input).val();
    },
  },
  set_value_func: {
    'checkbox': function ($input, value) {
      $input.prop('checked', value);
    },
    'radio': function ($input, value) {
      $input.filter('[value=' + (value in this.to_radio_value ? this.to_radio_value[value] : value) + ']').prop('checked', true);
    },
    'select-one': function ($input, value) {
      $input.val(value);
    },
    'text': function ($input, value) {
      $input.val(value);
    },
    'number': function ($input, value) {
      $input.val(value);
    },
  },
  comparators: {
    '==': function (actual, expected) {
      if (Array.isArray(actual) && Array.isArray(expected)){
        return actual.length === expected.length && actual.every(function (element, index) {
            return element === expected[index];
        });
      } else {
        return actual === expected;
      }

    },
    'contains': function (actual, expected) {
      return actual && actual.indexOf(expected) !== -1;
    },
    'not_contains': function (actual, expected) {
      return actual ? actual.indexOf(expected) === -1 : true;
    }
  },
  initialize: function (options) {
    this.field_relations = options.field_relations;
    this.venueId = options.venueId
    this._init_onchange_handlers();
    this.venue_group_select = new sr.AsyncSelect({
      select: options.root.querySelector('#venue-group-select'),
      selected_id: options.venue_group_id,
      service: Pmp.Admin.VenueGroupsService,
    });
    this.neighborhood_select = new sr.AsyncSelect({
      select: options.root.querySelector('#neighborhood-select'),
      selected_id: options.neighborhood_id,
      service: Pmp.Admin.NeighborhoodsService,
      create_option: function (item, selected_id) {
        var option = this._create_option_base(item, selected_id);
        option.dataset.country = item.country_code;
        return option;
      },
    });
    if (options.is_create_venue) {
      this.settings_source_venue_select = new sr.AsyncSelect({
        select: options.root.querySelector('#settings-source-venue-select'),
        service: Pmp.Admin.SourceVenuesService,
      });
    }
  },

  _handleRebuild: function(url, params, button) {
    $(button).prop('disabled', true)
    $('#rebuild-banner').hide()

    $.post(url, params).done(response => {
      $('#rebuild-banner').show().removeClass('error-message').addClass('gold-message').html(response.payload.message.replace('\n', '<br />'))
    }).fail(response => {
      const payload = JSON.parse(response.responseText).payload
      $('#rebuild-banner').show().removeClass('gold-message').addClass('error-message').text(payload.message)
    }).always(() => {
      $(button).prop('disabled', false)
    })
  },

  _init_onchange_handlers: function () {
    for (var field_name in this.field_relations) {
      if (Object.prototype.hasOwnProperty.call(this.field_relations, field_name)) {
        this._attach_onchange_handler(field_name, this.field_relations[field_name])
      }
    }
  },
  _attach_onchange_handler: function (field_name, affected_fields) {
    var $input = $('[name=' + field_name + ']');
    var input_type = $input.prop('type');
    var get_value_func = this.get_value_func[input_type];
    if (!get_value_func) {
      throw 'Unsupported field type: ' + input_type + ' or field "' + field_name + '" not found';
    }
    $input.on('change', this.create_onchange_handler(affected_fields, get_value_func));
  },
  create_onchange_handler: function (dependent_fields, get_value) {
    var self = this;
    return function () {
      for (var i = 0; i < dependent_fields.length; i++) {
        var dependent_fields_row = dependent_fields[i];
        var comparator_name = dependent_fields_row[0];
        var comparator = self.comparators[comparator_name];
        if (!comparator) {
          throw 'Unsupported comparator ' + comparator_name;
        }
        if (comparator(get_value.call(self, this), dependent_fields_row[1])) {
          self.apply_field_values(dependent_fields_row[2]);
        }
      }
    }
  },
  apply_field_values: function (field_values) {
    for (var field_name in field_values) {
      if (Object.prototype.hasOwnProperty.call(field_values, field_name)) {
        this.apply_field_value(field_name, field_values[field_name]);
      }
    }
  },
  apply_field_value: function (field_name, value) {
    var $input = $('[name=' + field_name + ']');
    if (!$input.length) {
      // Field is not configurable via the venue edit page
      return
    }
    var input_type = $input.prop('type');
    var set_value_func = this.set_value_func[input_type];
    if (!set_value_func) {
      throw 'Unsupported field type: ' + input_type + ' or field "' + field_name + '" not found';
    }
    set_value_func.call(this, $input, value);
  },
};
