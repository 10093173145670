// @ts-ignore
var Pmp = Pmp || {}
Pmp.Admin = Pmp.Admin || {}
Pmp.Admin.Ivvy = Pmp.Admin.Ivvy || {}

interface IvvyVenue {
  id: number
  name: string
}

Pmp.Admin.Ivvy.Edit = {
  initialize() {
    $('#venue_id_pull_button').on('click', e => {
      e.preventDefault()
      this.test_connection(true)
    })
    $('#venue_id_manual_button').on('click', e => {
      e.preventDefault()
      var $venue_id_select = $('#venue_id_select')
      var $venue_id_input = $('#venue_id_input')
      $venue_id_input.attr('name', 'ivvy_venue_id').val($venue_id_select.val()!).show()
      $venue_id_select.attr('name', '').hide()
      $('#venue_id_pull_button').show()
      $('#venue_id_manual_button').hide()
    })
    $('#test_connection').on('click', e => {
      e.preventDefault()
      this.test_connection()
    })
    $('[name=mapping_strategy]').on('change', e => {
      $('.strategy_description').hide()
      const selected = (e.target as HTMLSelectElement).value
      $(`#strategy_description_${selected}`).show()
    })
  },
  show_venue_id_select(venues: IvvyVenue[], force = false) {
    var $venue_id_input = $('#venue_id_input')
    var $venue_id_select = $('#venue_id_select')
    if ($venue_id_input.val() && !force) {
      return
    }
    $venue_id_input.hide().attr('name', '')
    $venue_id_select.show().attr('name', 'ivvy_venue_id')
    $('#venue_id_pull_button').hide()
    $('#venue_id_manual_button').show()
    $venue_id_select.empty()
    _.sortBy(venues, 'name').forEach(venue => {
      $venue_id_select.append('<option value="' + venue.id + '">' + _.escape(venue.name) + '</option>')
    })
    $venue_id_select.val($venue_id_input.val()!)
  },
  test_connection(pull_only = false) {
    var api_key = $('input[name=api_key]').val()
    var api_secret = $('input[name=api_secret]').val()
    var api_environment = $('select[name=api_environment]').val()
    if (!api_key || !api_secret || !api_environment) {
      alert('Please fill up API Key and API Secret, and choose an Environment before hitting the test connection')
      return
    }
    $('#test_connection').prop('disabled', true)
    $.ajax({
      method: 'POST',
      url: '/api-yoa/admin/venue/ivvy/test_connection',
      contentType: 'application/json',
      data: JSON.stringify({
        api_key: api_key,
        api_secret: api_secret,
        api_environment: api_environment,
      }),
      success: (data: { data: { success: boolean; venues?: IvvyVenue[]; reason: string } }) => {
        $('#test_connection').prop('disabled', false)
        if (data.data.success) {
          if (!pull_only) {
            alert('Test connection credentials are successful!')
          }
          if (data.data.venues && data.data.venues.length > 0) {
            this.show_venue_id_select(data.data.venues, pull_only)
          }
        } else if (data.data.reason === 'forbidden') {
          alert('Credentials are invalid (try other Environments if unsure)')
        } else {
          alert('Error')
        }
      },
      error: err => {
        $('#test_connection').prop('disabled', false)
        var response = JSON.parse(err.responseText)
        alert(response.msg)
      },
    })
  },
  generate_table_controls(row_index: number, ivvy_table: string, sevenrooms_tables: string[]) {
    return `<br><input type="text" style="margin-right: 10px; float: left;" name="ivvy-table-${row_index}" value="${ivvy_table}" placeholder="iVvy space id" /><textarea type="text" style="height: 15px" name="sr-table-${row_index}" placeholder="sevenrooms table(s)">${sevenrooms_tables.join(
      ', '
    )}</textarea><br>`
  },
}
