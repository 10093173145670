var Pmp = Pmp || {};
Pmp.Reservation = Pmp.Reservation || {};

Pmp.Reservation.EmailSubscription = {
    initialize: function(venueGroupClientId) {
        this._venueGroupClientId = venueGroupClientId;
        this.bindVenueGroupClick();
        this.bindVenueSpecificClick();
        this.bindSubmitHandlers();
    },

    bindVenueGroupClick: function() {
        var venueGroupMarketingBtn = $('input[name=venueGroupMarketingOptIn');
        venueGroupMarketingBtn.click(function() {
            var makeChecked = venueGroupMarketingBtn.is(':checked');
            $('input[name=venueMarketingOptIn').each(function() {
                $(this).prop('checked', makeChecked);
            })
        });
    },

    bindVenueSpecificClick: function() {
        var venueSpecificMarketing = $('input[name=venueMarketingOptIn');
        venueSpecificMarketing.click(function() {
            var allChecked = true;
            venueSpecificMarketing.each(function() {
                if (!$(this).is(':checked')) {
                    allChecked = false;
                    return;
                }
            })
            $('input[name=venueGroupMarketingOptIn').prop('checked', allChecked);
        })
    },

    bindSubmitHandlers: function() {
        var self = this;
        $('#email-subscription-submit-btn').click(function() {
            self.submitForm(removeAll=false);
        });
        $('#email-subscription-remove-all').click(function() {
            self.submitForm(removeAll=true);
        });
    },

    submitForm: function(removeAll) {
        var self = this;
        var venueGroupMarketingOptIn = removeAll ? 0 : $('input[name=venueGroupMarketingOptIn]').is(':checked') ? 1 : 0;
        var optOutVenues = []
        var optInVenues = []
        $('input[name=venueMarketingOptIn').each(function() {
            var checkbox = $(this);
            var venueId = checkbox.attr('data-val');
            if (!checkbox.is(':checked') || removeAll) {
                optOutVenues.push(venueId);
            } else {
                optInVenues.push(venueId);
            }
        })
        data = {
            'venueGroupMarketingOptIn': venueGroupMarketingOptIn,
            'optOutVenues': optOutVenues,
            'optInVenues': optInVenues,
        }
        $.ajax({
            url: '/direct/email-subscriptions/' + self._venueGroupClientId + "/saveEmailSubscriptions",
            type: 'POST',
            data: data,
            success : function (response) {
                if(!response) { 
                    errorMsg = $('#error-msg');
                    errorMsg.html('Submission error. Please try again later.');
                    errorMsg.show();
                }
                else {
                    $('#email-subscriptions-body').hide();
                    $('#email-subscription-header').html('Email Preferences have been updated');
                    window.scrollTo(0, 0);
                }
            }
        });
    },
}