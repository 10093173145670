var Pmp = Pmp || {};
Pmp.Manager = Pmp.Manager || {};

Pmp.Manager.Dedupe = {
    initialize: function () {
        this.clientIdsInput = $('input[name=client-ids]');
        this.venueInput = $('input[name=venue]');
        this.processButton = $('input[name=process]').length ? $('input[name=process]') : $('a[name=process]');
        this.bindEventHandlers();
    },

    openUrl: function (verb, url, data, target) {
        var form = $("<form/>", {
            action: url,
            method: verb,
            target: target || "_self",
            style: 'display: none'
        });
        form.append(Pmp.Client.Static.CsrfInput());

        _.map(data, function (value, key) {
            var input = $("<textarea/>", {
                name: key,
                value: typeof data[key] === "object" ? JSON.stringify(value) : value
            });
            form.append(input);
        });

        $('body').append(form);
        form.submit();
    },

    bindEventHandlers: function () {
        this.debug("bindEventHandlers");
        this.processButton.on('click', _.bind(this.onSubmitProcess, this));
    },

    onSubmitProcess: function () {
        var client_lists = this.clientIdsInput.val().split("|"),
            client_ids = _.first(client_lists).split(","),
            remaining_list = _.rest(client_lists),
            venue_name = this.venueInput.val().toLowerCase(),
            merge_url = '/manager/' + venue_name + '/clients/merge',
            params = _.reduce(client_ids, function (memo, attr, v, i) {
                    memo = _.extend(memo, _.object(['vgcid' + v], [attr]));
                    return memo;
                },
                {});
        params = _.extend(params, {numvgc: _.size(params)});
        this.openUrl('POST', merge_url, params, '_blank');
        this.clientIdsInput.val(remaining_list.join("|"))
    },

    log: function (msg) {
        console.log("Pmp.Admin.Clients: " + msg);
    },

    debug: function (msg) {
        if (Pmp.Settings.DEBUG) {
            this.log(msg);
        }
    },

};