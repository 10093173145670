var sr = sr || {};

sr.AsyncSelect = (function () {
  function AsyncSelect(options) {
    this._create_option = options.create_option || this._create_option_base;
    this.$select = $(options.select);
    this._$select_wrapper = this.$select.parent();
    this.selected_id = options.selected_id;
    this.service = options.service;
    this.service.fetch(this._on_fetch.bind(this));
    setTimeout(function () {
      this.$select.select2();
    }.bind(this), 0);
  }

  AsyncSelect.prototype._on_fetch = function (entities) {
    if (entities !== null) {
      this._render_items(entities);
    } else {
      this._render_error();
    }
  };
  AsyncSelect.prototype._render_items = function (items) {
    const selected_id = this.selected_id;
    if (selected_id && items.find(function (item) {return item.id === selected_id})) {
      this.$select.find('option[value=' + selected_id + ']').remove();
    }
    for (var i = 0; i < items.length; i++) {
      this.$select.append(this._create_option(items[i], selected_id));
    }
    this._remove_reload_controls();
  };
  AsyncSelect.prototype._create_option_base = function (item, selected_id) {
      const is_selected = item.id === selected_id;
      return new Option(item[this.service.label_field], item.id, is_selected, is_selected);
  };
  AsyncSelect.prototype._render_error = function () {
    this._init_reload_controls();
    this._$select_wrapper.hide();
    this._$loading_label.hide();
    this._$reload_button.show();
  };
  AsyncSelect.prototype._init_reload_controls = function () {
    if (this._has_reload_controls) {
      return;
    }
    this._has_reload_controls = true;
    this._$reload_button = $('<div class="select_error"><a href="#">Failed to load ' + this.service.entity_label + ', click here to try again</a></div>')
      .on('click', this._on_click.bind(this))
      .insertAfter(this._$select_wrapper)
      .hide();
    this._$loading_label = $('<div class="select_loading_message">Loading ' + this.service.entity_label + ' ...</div>')
      .insertAfter(this._$select_wrapper)
      .hide();
  };
  AsyncSelect.prototype._remove_reload_controls = function () {
    if (!this._has_reload_controls) {
      return;
    }
    this._has_reload_controls = false;
    this._$reload_button.remove();
    this._$loading_label.remove();
  };
  AsyncSelect.prototype._on_click = function (e) {
    e.preventDefault();
    this._$reload_button.hide();
    this._$loading_label.show();
    this.service.fetch(this._on_fetch.bind(this));
  };
  return AsyncSelect;
})();
