
var Pmp = Pmp || {};
Pmp.Main = Pmp.Main || {};
Pmp.Main.Popup = Pmp.Main.Popup || {};

Pmp.Main.Popup.Base = {
	initialize : function() {
		this._popup_parent_id = '#main-popup-parent';
		this._popup_id = '#main-popup';
		this.bindLiveClickHandlers();
	},

	bindLiveClickHandlers : function() {
		// Create a live click handler (not using the page utility) so that it's not removed by unajaxify
		// This get's called by main.soy (once overall) and not by every ajax template load
		$('body').on('click', '.close-main-colorbox-link', $.colorbox.close);
	},
	
	waitForLoadAndResize : function() {
		Pmp.Utils.ResizeColorboxOnLoad(this._popup_id);
	},
	
	loadHtml : function(html) {
		$(this._popup_id).html(html);
	},
	
	loadUrl : function(url, doWaitForLoadAndResize, popupId) {
		var targetId = this._popup_id;
		if (popupId != undefined) {
			targetId = popupId;
		}
		var onLoadFn = undefined;
		if (doWaitForLoadAndResize) {
			onLoadFn = this.waitForLoadAndResize;
			$(targetId).html("<center><div id='main-popup-loading'>&nbsp;</div></center>"); // clear & show loading
		}
		Pmp.Utils.LoadUrl(url, targetId, onLoadFn);
	},
	
	showPopup : function(width, onCompleteFnHook, onClosedFnHook, popupId, popupParentId) {
		
	  var targetId = this._popup_id;
	  if (popupId != undefined) {
	  	  targetId = popupId;
	  } 
	  var targetParentId = this._popup_parent_id;
	  if (popupParentId != undefined) {
		  targetParentId = popupParentId;
	  }
	  var page = Pmp.Client.Static.Page;
	  $('#manager').trigger('poppedcolorbox');
	  Pmp.Utils.CreateDynamicPopup(
			targetParentId,
			targetId, 
			page,
			width,
			onCompleteFnHook,
			onClosedFnHook
		);
	}
};
