// This file was automatically generated from splash.mfa.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Homepage == 'undefined') { Nightloop.Templates.Homepage = {}; }


Nightloop.Templates.Homepage.Multifactor = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Homepage.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Homepage.MultifactorBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Homepage.MultifactorBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="main login"><div class="content-wrapper"><div class="align"><div class="form-wrapper"><div id="mfa-render"></div></div></div></div></div><iframe class="main ad-container" src="', soy.$$escapeHtml(opt_data.wordpress_ad_url), '"></iframe><script type="text/javascript" async src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['frontend/fortawesome']), '"><\/script><script type="text/javascript">\n      var globalInit = globalInit || {};\n      globalInit.mfa = ', opt_data.content, ';<\/script><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['app/widget/login.js']), '" crossorigin="anonymous" defer><\/script>');
  return opt_sb ? '' : output.toString();
};
