var RequestBook = {
	init : function () {
		console.log('init baby!');
		Chatter.init();
		var book_form = $('#book-form');
		$('#book-btn').on('click', function() {
      if ($(this).hasClass('disabled')) {
        return;
      }
      _button_helper(this);
			book_form.submit();
		});
		var decline_form = $('#decline-form');
		$('#decline-btn').on('click', function() {
      if ($(this).hasClass('disabled')) {
        return;
      }
      _button_helper(this);
			decline_form.submit();
		});
		
		var cancel_form = $('#cancel-form');
		$('#cancel-btn').on('click', function() {
      if ($(this).hasClass('disabled')) {
        return;
      }
		  _button_helper(this);
			cancel_form.submit();
		});
		
		var _button_helper = function(el) {
      $(el).sext('Loading...');
      $(el).addClass('disabled');		  
		}
	}
}


$(function() {
  if( !$('#page-request-book').length ) return;
  RequestBook.init();
});