// This file was automatically generated from admin.concierge.logos.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ConciergeLogosEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ConciergeLogosEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ConciergeLogosEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-concierge-edit"><div class="images-upload-area"><div><a href="/admin/concierge/', soy.$$escapeHtml(opt_data.content.concierge.id), '/edit">&lt;&lt; back to concierge edit</a></div><h3>', soy.$$escapeHtml(opt_data.content.concierge.name), ' Concierge Logos</h3><div><p>White text:', (opt_data.content.concierge.logo_white_size_map) ? '<div style="background-color:#000;"><img src="/.h/download/' + soy.$$escapeHtml(opt_data.content.concierge.logo_white_size_map.small) + '" /></div>' : '[none uploaded]', '</p><p>Black text:', (opt_data.content.concierge.logo_black_size_map) ? '<div style="background-color:#FFF;"><img src="/.h/download/' + soy.$$escapeHtml(opt_data.content.concierge.logo_black_size_map.small) + '" /></div>' : '[none uploaded]', '</p></div><div><form enctype="multipart/form-data" action="', soy.$$escapeHtml(opt_data.content.upload_form_url), '" method="post"><p>Upload new logos (these should have transparent backgrounds) <br/>White text: <input type="file" name="logowhite" /> <br/>Black text: <input type="file" name="logoblack" /></p><input type="submit" value="Submit"></form></div></div></div>');
  return opt_sb ? '' : output.toString();
};
