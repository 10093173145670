// This file was automatically generated from admin.concierge.disable.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.DisableVenues = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.DisableVenuesBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.DisableVenuesBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venues-enable"><div><a href="/admin/concierge/', soy.$$escapeHtml(opt_data.content.concierge_id), '/edit">&lt;&lt; back to concierge edit</a></div><h3>Bulk Disable Concierge: ', soy.$$escapeHtml(opt_data.content.concierge_name), '</h3>', (opt_data.content.error_msg) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.content.error_msg) + '</div>' : '', (opt_data.content.success_message) ? '<div class="gold-message">' + soy.$$escapeHtml(opt_data.content.success_message) + '</div>' : '', '<form id="enable-venues-form" method="POST" action="/admin/concierge/', soy.$$escapeHtml(opt_data.content.concierge_id), '/disable"><h4>1. Select venues to update</h4><table style="width:100%;"><tr><td class="form-label">Concierge:</td><td>', soy.$$escapeHtml(opt_data.content.concierge_name), '</td></tr><tr><td class="form-label">To Venues:<div style="font-style:italic;color:#999;">(urlkeys or IDs, separated by commas or new lines. maximum 500 different venues at a time)</div></td><td><textarea name="venue_disable_ids" style="width:100%; height:100px;"></textarea></td></tr></table><label> Delimiter<select name="delimiter"><option value="comma"> Comma </option><option value="newline"> New line </option></select></label><div><h7>Running this tool will Blacklist this concierge for any venue with "Concierges" or an existing Blacklist and will add it to any Blacklist at your selected venues. This will also turn off Concierge for any venue as well.</h7></div><div class="float-right"><input type="submit" value="Bulk Disable" /></div><div class="clearfix"></div></form></div>');
  return opt_sb ? '' : output.toString();
};
