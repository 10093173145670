// This file was automatically generated from widget.membergroup.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.MembershipGroup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div group_id="', soy.$$escapeHtml(opt_data.group_id), '" group_name="', soy.$$escapeHtml(opt_data.group_name), '" group_color="', soy.$$escapeHtml(opt_data.group_color), '" class="member-group ', (opt_data.clickable) ? 'clickable' : '', ' ', (opt_data.closer) ? 'closable' : '', '" style="background:', soy.$$escapeHtml(opt_data.group_color), '" ><span class="name">', soy.$$escapeHtml(opt_data.group_name), '</span>', (opt_data.closer) ? '<span class="closer">&times;</span>' : '', (opt_data.input) ? '<input type="hidden" value="' + soy.$$escapeHtml(opt_data.group_id) + '" name="group_id" />' : '', '</div>');
  return opt_sb ? '' : output.toString();
};
