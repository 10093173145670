var Pmp = Pmp || {}
Pmp.Common = Pmp.Common || {}

Pmp.Common.VersionCheck = {
  initialize: function (site_version) {
    var url_version = this.get_url_version()
    if (url_version && url_version !== site_version) {
      this.show_mismatched_version_error(site_version, url_version)
    }
  },
  get_url_version: function () {
    var qa_hostname_match = document.location.hostname.match('(.*)-app.qa.sevenrooms.com')
    if (qa_hostname_match) {
      return qa_hostname_match[1]
    }
  },
  show_mismatched_version_error: function (appengine_version, url_version) {
    var message = document.createElement('div')
    message.textContent = 'The url version "' + url_version + '" does not match the deployed version! You are viewing "' + appengine_version + '" instead'
    message.style.position = 'fixed'
    message.style.top = '0'
    message.style.left = '160px'
    message.style.right = '160px'
    message.style.height = '32px'
    message.style.lineHeight = '32px'
    message.style.background = '#b16'
    message.style.color = '#fff'
    message.style.zIndex = '9999'
    message.style.textAlign = 'center'
    message.style.pointerEvents = 'none'
    document.body.appendChild(message)
    setTimeout(function () {
      message.style.display = 'none'
    }, 5000)
  },
};
