// This file was automatically generated from widget.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Layouts == 'undefined') { Nightloop.Layouts = {}; }


Nightloop.Layouts.Widget = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<!DOCTYPE html><html lang="en" translate="no">');
  Closure.Util.InsertTemplate(soy.$$augmentData(opt_data, {DynamicTemplate: opt_data.pageTemplate}), output);
  output.append('</html>');
  return opt_sb ? '' : output.toString();
};
