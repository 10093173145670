var Pmp = Pmp || {};
Pmp.Admin = Pmp.Admin || {};

Pmp.Admin.Squid = {
    initialize : function(minus_img, create_squid_url, update_squid_url, category, salesforce_id, has_squid, squid_id, parent_id) {
        this._minus_img = minus_img;
        this._create_squid_url = create_squid_url;
        this._update_squid_url = update_squid_url;
        this._category = category;
        this._salesforce_id = salesforce_id;
        this._has_squid = has_squid;
        this._squid_id = squid_id;
        this._parent_id = parent_id;
        this._bindEventHandlers();
	},

	log : function(msg) {
		console.log("Pmp.Admin.Squid: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	_bindEventHandlers : function() {
		this.debug("bindEventHandlers");
		var self = this;
		self._initSquid();
	},

	_initSquid : function() {
        var that = this;
		$('#squid-dropdown').change(function() {
            if (!$('#selected_list > li#' + $('#squid-dropdown').val()).length) {
                var img = $(
                    '<img class="remove-venue" src="' + that._minus_img + '" \
                    style="width:13px;height:13px;padding-left:10px;cursor:pointer;"/>'
                ).on("click",function() { $(this).parent().remove() });
                var li = $(
                    '<li id="' + $('#squid-dropdown').val() +'">' + $('#squid-dropdown').find('option:selected').text() + '</li>'
                );
                $("#selected_list").append(li.append(img));
                if ($('#create-squid').is(":hidden")) {
                    $("#update-squid").show();
                };
            };
            $('#squid-dropdown').val('');
        });
        $('img').bind('click', function() {
            $(this).parent().remove();
            if ($('#create-squid').is(":hidden")) {
                $("#update-squid").show();
            };
        });
        $('#create-squid').click(function() {
            var parent_id = $('#squid-parent-id').val();
            var entity_ids = $('#selected_list li').map(function(){return this.id}).get();
            $('#ajax-spinner').show();
            $(document.body).css('pointer-events', 'none');
            if (this.id === 'create-squid') {
                var url = that._create_squid_url
            } else if (this.id === 'update-squid') {
                var url = that._update_squid_url
            };

            $.ajax({
                type: "POST",
                url: url,
                data: {
                    salesforce_id: that._salesforce_id,
                    category: that._category,
                    entity_ids: JSON.stringify(entity_ids),
                    parent_id: parent_id,
                },
                success: function(squid_data) {
                    squid_data = JSON.parse(squid_data)  
                    $('#squid-value').val(squid_data['squid_id']);
                    $('#squid-value').prop('readonly', true);
                    $('#squid-results').show();
                    if (squid_data['parent_id']) {
                        $('#squid-parent-id').val(squid_data['parent_id']);
                        $('#squid-parent-id').prop('readonly', true);
                    }
                    $('#create-squid').hide();
                    $("#parent-squid-error").hide();
                    $('#ajax-spinner').hide();
                    $(document.body).css('pointer-events', 'auto');
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    if (xhr.responseText === 'Parent Squid does not exist.') {
                        $("#parent-squid-error").show();
                        $("#update-squid").hide();
                        $('#ajax-spinner').hide();
                        $(document.body).css('pointer-events', 'auto');
                    }
                }
            });
        });
        $('#update-squid').click(function() {
            var parent_id = $('#squid-parent-id').val();
            var squid_id = $('#squid-value').val();
            var entity_ids = $('#selected_list li').map(function(){return this.id}).get();
            $('#ajax-spinner').show();
            $(document.body).css('pointer-events', 'none');
            $.ajax({
                type: "POST",
                url: update_squid_url,
                data: {
                    squid_id: squid_id,
                    salesforce_id: that._salesforce_id,
                    category: that._category,
                    entity_ids: JSON.stringify(entity_ids),
                    parent_id: parent_id,
                },
                success: function(data) {
                    $("#update-squid").hide();
                    $("#parent-squid-error").hide();
                    $('#ajax-spinner').hide();
                    $(document.body).css('pointer-events', 'auto');
                    if (parent_id != '') {
                        $('#squid-parent-id').prop('readonly', true);
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    if (xhr.responseText === 'Parent Squid does not exist.') {
                        $("#parent-squid-error").show();
                        $("#update-squid").hide();
                        $('#ajax-spinner').hide();
                        $(document.body).css('pointer-events', 'auto');
                    }
                }
            });
        });
        $('#squid-parent-id').on('input', function() {
            if ($('#create-squid').is(":hidden")) {
                $("#update-squid").show();
            };
        });
        if (that._has_squid == 'True') {
            $('#create-squid').hide();
        };
        if (that._squid_id != '') {
            $('#squid-value').prop('readonly', true);
        };
        if (that._parent_id != '') {
            $('#squid-parent-id').prop('readonly', true);
        };
	}
};
