// This file was automatically generated from direct.venuepolicy.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Direct == 'undefined') { Nightloop.Templates.Direct = {}; }


Nightloop.Templates.Direct.VenuePolicy = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Direct.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Direct.VenuePolicyBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Direct.VenuePolicyBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-venuepolicy"><div class="content-header">', soy.$$escapeHtml(opt_data.content.venue_name), ' ', soy.$$escapeHtml(opt_data.content.venue_class_name), ' Policy</div><div id="main-area" class="w60"><div id="main-body"><div class="subtext">', opt_data.content.venue_policy, '</div></div></div></div>');
  return opt_sb ? '' : output.toString();
};
