// @ts-ignore
const Pmp = Pmp || {}
Pmp.Admin = Pmp.Admin || {}
Pmp.Admin.VenueGroup = Pmp.Admin.VenueGroup || {}

Pmp.Admin.VenueGroup.Edit = {
  initialize() {
    $('#test_opera_connection').on('click', e => {
      e.preventDefault()
      this.testOperaConnection()
    })

    $('#test_infor_connection').on('click', e => {
      e.preventDefault()
      this.testInforConnection()
    })

    $('#test_hapi_connection').on('click', e => {
      e.preventDefault()
      this.testHapiConnection()
    })

    $('#test_htng_connection').on('click', e => {
      e.preventDefault()
      this.testHTNGConnection()
    })
  },

  testOperaConnection() {
    const operaApiBaseUrl = $('input[name=opera_api_base_url]').val()
    const operaUsername = $('input[name=opera_username]').val()
    const operaPassword = $('input[name=opera_password]').val()
    const operaClientSecret = $('input[name=opera_client_secret]').val()
    const operaClientId = $('input[name=opera_client_id]').val()
    const operaEnterpriseId = $('input[name=opera_enterprise_id]').val()

    if (!operaApiBaseUrl) {
      alert('Please fill API base url')
      return
    }
    if (!operaClientSecret) {
      alert('Please fill Client Secret')
      return
    }

    const is_username_password = operaUsername && operaPassword
    const is_enterprise_client = operaEnterpriseId && operaClientId

    if (!is_username_password && !is_enterprise_client) {
      alert('Please fill Username/Password or Enterprise ID/Client ID')
      return
    }

    $('#test_opera_connection').prop('disabled', true)
    $.ajax({
      method: 'POST',
      url: '/api-yoa/admin/venuegroup/test_opera_connection',
      contentType: 'application/json',
      data: JSON.stringify({
        operaApiBaseUrl,
        operaUsername,
        operaPassword,
        operaClientSecret,
        operaClientId,
        operaEnterpriseId,
      }),
      success: (response: { data: { success: boolean; reason: string } }) => {
        const { success, reason } = response.data
        $('#test_opera_connection').prop('disabled', false)
        if (success) {
          alert('Connection success!')
        } else {
          alert(`Connection failed: ${reason}`)
        }
      },
      error: error => {
        $('#test_opera_connection').prop('disabled', false)
        const response = JSON.parse(error.responseText)
        alert(response.msg)
      },
    })
  },

  testInforConnection() {
    const inforApiBaseUrl = $('input[name=infor_api_base_url]').val()
    const inforUsername = $('input[name=infor_username]').val()
    const inforPassword = $('input[name=infor_password]').val()
    const inforHotelId = $('input[name=infor_hotel_id]').val()

    if (![inforApiBaseUrl, inforUsername, inforPassword, inforHotelId].every(x => x)) {
      alert('Please fill in all fields')
      return
    }

    $('#test_infor_connection').prop('disabled', true)
    $.ajax({
      method: 'POST',
      url: '/api-yoa/admin/venuegroup/test_infor_connection',
      contentType: 'application/json',
      data: JSON.stringify({
        inforApiBaseUrl,
        inforUsername,
        inforPassword,
        inforHotelId,
      }),
      success: (response: { data: { success: boolean; reason: string } }) => {
        const { success, reason } = response.data
        $('#test_infor_connection').prop('disabled', false)
        if (success) {
          alert('Connection success!')
        } else {
          alert(`Connection failed: ${reason}`)
        }
      },
      error: error => {
        $('#test_infor_connection').prop('disabled', false)
        const response = JSON.parse(error.responseText)
        alert(response.msg)
      },
    })
  },

  testHapiConnection() {
    const hapiApiBaseUrl = $('input[name=hapi_api_base_url]').val()
    const hapiUsername = $('input[name=hapi_username]').val()
    const hapiPassword = $('input[name=hapi_password]').val()

    if (![hapiApiBaseUrl, hapiUsername, hapiPassword].every(x => x)) {
      alert('Please fill in all fields')
      return
    }

    $('#test_hapi_connection').prop('disabled', true)
    $.ajax({
      method: 'POST',
      url: '/api-yoa/admin/venuegroup/test_hapi_connection',
      contentType: 'application/json',
      data: JSON.stringify({
        hapiApiBaseUrl,
        hapiUsername,
        hapiPassword,
      }),
      success: (response: { data: { success: boolean; reason: string } }) => {
        const { success, reason } = response.data
        $('#test_hapi_connection').prop('disabled', false)
        if (success) {
          alert('Connection success!')
        } else {
          alert(`Connection failed: ${reason}`)
        }
      },
      error: error => {
        $('#test_hapi_connection').prop('disabled', false)
        const response = JSON.parse(error.responseText)
        alert(response.msg)
      },
    })
  },

  testHTNGConnection() {
    const htngApiBaseUrl = $('input[name=htng_api_base_url]').val()
    const htngUsername = $('input[name=htng_username]').val()
    const htngPassword = $('input[name=htng_password]').val()
    const htngHotelId = $('input[name=htng_hotel_id]').val()

    if (![htngApiBaseUrl, htngUsername, htngPassword, htngHotelId].every(x => x)) {
      alert('Please fill in all fields')
      return
    }

    $('#test_htng_connection').prop('disabled', true)
    $.ajax({
      method: 'POST',
      url: '/api-yoa/admin/venuegroup/test_htng_connection',
      contentType: 'application/json',
      data: JSON.stringify({
        htngApiBaseUrl,
        htngUsername,
        htngPassword,
        htngHotelId,
      }),
      success: (response: { data: { success: boolean; reason: string } }) => {
        const { success, reason } = response.data
        $('#test_htng_connection').prop('disabled', false)
        if (success) {
          alert('Connection success!')
        } else {
          alert(`Connection failed: ${reason}`)
        }
      },
      error: error => {
        $('#test_htng_connection').prop('disabled', false)
        const response = JSON.parse(error.responseText)
        alert(response.msg)
      },
    })
  },
}
