// This file was automatically generated from admin.resetdevice.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ResetDevice = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ResetDeviceBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ResetDeviceBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-utilities-reset-device">', (opt_data.success_message) ? '<div class="gold-message">' + soy.$$escapeHtml(opt_data.success_message) + '</div>' : '', (opt_data.error_message) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.error_message) + '</div>' : '', '<h2>Force reset mobile device</h2><p><form method="post" action="/admin/reset_device"><input id="sr-user-id" placeholder="User ID" name="user_id" type="text" style="width: 250px;" /><br /><input id="sr-device-id" placeholder="Enter Device ID" name="device_id" type="text" style="width: 250px;" /><br /><input id="sr-venue-id" placeholder="Enter Venue ID" name="venue_id" type="text" style="width: 250px;" required/><br /><br /><input id="submit-reset-device" type="submit" value="Submit" /></form></p></div>');
  return opt_sb ? '' : output.toString();
};
