// This file was automatically generated from admin.municipality.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.MunicipalityList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.MunicipalityListBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.MunicipalityListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="content-header">Municipalities</div><ul>');
  var municipalityList1164 = opt_data.content.municipalities;
  var municipalityListLen1164 = municipalityList1164.length;
  for (var municipalityIndex1164 = 0; municipalityIndex1164 < municipalityListLen1164; municipalityIndex1164++) {
    var municipalityData1164 = municipalityList1164[municipalityIndex1164];
    output.append((municipalityIndex1164 % 2 == 0) ? '<li><a class="ajaxify muni-edit-link row-even" href="/admin/municipality/edit/' + soy.$$escapeHtml(municipalityData1164.id) + '">' + soy.$$escapeHtml(municipalityData1164.name) + '/' + soy.$$escapeHtml(municipalityData1164.state) + '/' + soy.$$escapeHtml(municipalityData1164.country) + '</a></li>' : '<li><a class="ajaxify muni-edit-link row-odd" href="/admin/municipality/edit/' + soy.$$escapeHtml(municipalityData1164.id) + '">' + soy.$$escapeHtml(municipalityData1164.name) + '/' + soy.$$escapeHtml(municipalityData1164.state) + '/' + soy.$$escapeHtml(municipalityData1164.country) + '</a></li>');
  }
  output.append('</ul><a id="muni-add-link" class="ajaxify" href="/admin/municipality/edit/">add municipality</a>');
  return opt_sb ? '' : output.toString();
};
