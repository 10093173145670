var Pmp = Pmp || {};
Pmp.Common = Pmp.Common || {};
Pmp.Common.Entity = Pmp.Common.Entity || {};

Pmp.Common.Entity.Search = {
	initialize : function(base_url, entity_url_suffix, scope, onVenueSelect, useDisplayedItemsCountLimit, venue_group_id='') {
		this._kind = 'VENUE';
		this._useDisplayedItemsCountLimit = useDisplayedItemsCountLimit !== false;
		this._base_url = base_url;
		this._entity_url_suffix = entity_url_suffix;
		this._scope = scope;
		this._bindEventHandlers();
		this._do_search_obj = undefined;
		this._onVenueSelect = onVenueSelect || this._onVenueSelectDefault;
		this.venue_group_id = venue_group_id;

		var self = this;
		var destroyerFn = function() { self.destroy(); };
		Pmp.Client.Static.Factory.Register(Nightloop.Layouts.Main,
                null, destroyerFn);

		this._refresh_count = 0;

		// init placeholders for IE8
		$('#venue-search-input', $(this._scope)).placeholderLabel();

		if (this._venues === undefined) {
			this._refresh_count += 1;
			this._fetch_venues(true);
		}
	},

	log : function(msg) {
		console.log("Pmp.Common.Venue.Search: "+ msg);
	},

	debug : function(msg) {
		if (Pmp.Settings.DEBUG) {
			this.log(msg);
		}
	},

	destroy : function() {
		this.debug('destroying');
		this._clearSearch();
		$(document).unbind('mouseup');
	},

	_bindEventHandlers : function() {
		this.debug("_bindEventHandlers");
		var self = this;
		var venueSearchChangeFn = function(event) { self._venueSearchChange(event); };
		var venueSearchKeyupFn = function(event) { self._venueSearchKeyup(event); };
		$('#venue-search-input', $(this._scope)).change(venueSearchChangeFn);
		$('#venue-search-input', $(this._scope)).keyup(venueSearchKeyupFn);

		var page = Pmp.Client.Static.Page;
		page.AddLiveHandler(
			this._scope + ' .venue-search-result',
			'click',
			function(event) {
				self._clickVenueSearchResult(event, this);
			}
		);

		var onClickDocumentHideDropdown = function(event) {self._hideDropdownOnClick(event); };
		$(document).mouseup(onClickDocumentHideDropdown);
	},

	useVenueSearch : function() {
		this._kind = 'VENUE';
		this._venues = undefined;
	},

	isVenueSearch : function() {
		return this._kind == 'VENUE';
	},

	useConciergeSearch : function() {
		this._kind = 'CONCIERGE';
		this._venues = undefined;
	},

	isConciergeSearch : function() {
		return this._kind == 'CONCIERGE';
	},

	_get_search_query : function() {
		return $('#venue-search-input', $(this._scope)).val().toLowerCase();
	},

	_getPortalUrl : function(url_key) {
		if (this.isConciergeSearch()) {
			return '/concierge/'+url_key+this._entity_url_suffix;
		}
		return  '/manager/'+url_key+this._entity_url_suffix;
	},

	_hideDropdownOnClick : function(e) {
		var container = $("#venue-search-results", $(this._scope));
		if (container.has(e.target).length === 0) {
			$('#venue-search-results', $(this._scope)).addClass('no-display');
			this._clearSearch();
		}
	},

	_clickVenueSearchResult : function(event, el) {
		$(el).blur();
		this._clearSearch();
		$('#venue-search-results', $(this._scope)).addClass('no-display');

		this._onVenueSelect($(el).find('#venue_key'))
	},

	_onVenueSelectDefault : function($selectedEl) {
		window.location = this._getPortalUrl($selectedEl.html())
 	},

	_venueSearchChange : function(event) {
		this.debug('_venueSearchChange');
		this._setSearch();
	},

	_venueSearchKeyup : function(ev) {
		this.debug('_venueSearchKeyup');
		// detect enter, left, up, down, right
		if ((ev.which === 37) || (ev.which === 38)) {
			// left/up
			var selected = $('#venue-search-results .venue-search-result.selected', $(this._scope));
			var all_visible = $('#venue-search-results .venue-search-result', $(this._scope));
			var idx = all_visible.index(selected);
			if ((idx-1) >= 0) {
				var new_selected = all_visible.get(idx-1);
				if ($(new_selected).length > 0) {
					$(new_selected).addClass('selected');
					$(selected).removeClass('selected');
				}
			}
			ev.preventDefault();
		} else if ((ev.which === 39) || (ev.which === 40)){
			// right/down
			var selected = $('#venue-search-results .venue-search-result.selected', $(this._scope));
			var all_visible = $('#venue-search-results .venue-search-result', $(this._scope));
    		var idx = all_visible.index(selected);
    		if ((idx+1) < all_visible.length) {
    			var new_selected = all_visible.get(idx+1);
    			if ($(new_selected).length > 0) {
    				$(new_selected).addClass('selected');
    				$(selected).removeClass('selected');
    			}
    		}
				ev.preventDefault();
        } else if (ev.which === 13) {
        	// enter
        	var selected = $('#venue-search-results .venue-search-result.selected', $(this._scope));
        	if (selected.length > 0) {
        		this._clickVenueSearchResult(null,selected);
        	}
        	ev.preventDefault();
        } else {
        	this._setSearch();
        }
	},

	_startAnimating : function() {
		$('#venue-search-spinner', $(this._scope)).removeClass('no-display');
	},

	_stopAnimating : function() {
		$('#venue-search-spinner', $(this._scope)).addClass('no-display');
	},

	_clearSearch : function() {
		if (this._do_search_obj !== undefined) {
			clearTimeout(this._do_search_obj);
			this._do_search_obj = undefined;
		}
	},

	_setSearch : function(){
		this._clearSearch();
		var self = this;
		var doSearch = function() {self._doSearch();};
		this._do_search_obj = setTimeout(doSearch, 200);
	},

	_doSearch : function() {
		this.debug('_doSearch for venue search called.');
		this._startAnimating();
		this._clearSearch();
		var search_q = this._get_search_query();
		if (search_q !== '') {
			if (this._refresh_count % 50 === 0 || this._venues === undefined) {
				this._fetch_venues();
			}
			if (this._venues !== undefined) {
				this._handleLoadVenueSearchResults(this._venues);
			}
			if (this.isVenueSearch() && this._venues === undefined) {
				this._fetch_venues_with_query();
			}
			this._refresh_count += 1;
		} else {
			this._stopAnimating();
			$('#venue-search-results', $(this._scope)).addClass('no-display');
		}
	},

	_is_valid_response : function (data) {
		return !!(data && data.payload && data.payload.content && data.payload.content.entities)
	},

	_fetch_venues : function(results_hide) {
		var self = this;
		var onLoadFn = function(data) {
			if (!self._is_valid_response(data)) {
				return
			}
			self._venues = data.payload.content.entities;
			if (results_hide === undefined) {
				self._handleLoadVenueSearchResults(self._venues);
			}
		};
		var url = this._base_url + '/' + this._kind.toLowerCase() + '/search';
		if (this.venue_group_id) {
			url += '?venue_group_id=' + this.venue_group_id;
		};
		Pmp.Client.AsyncGet(url, onLoadFn);
	},

	_fetch_venues_with_query : function() {
		var self = this;
		var request_search_q = this._get_search_query();
		var onLoadFn = function(data) {
			if (!self._is_valid_response(data)) {
				return
			}
			var current_search_q = self._get_search_query();
			if (current_search_q !== request_search_q) {
				return
			}
			self._display_items(data.payload.content.entities)
		};
		var url = this._base_url + '/' + this._kind.toLowerCase() + '/search?q=' + request_search_q;
		if (this.venue_group_id) {
			url += '&venue_group_id=' + this.venue_group_id;
		};
		Pmp.Client.AsyncGet(url, onLoadFn);
	},

	_handleLoadVenueSearchResults : function(venues) {
		this.debug('_handleLoadVenueSearchResults called');
		var search_q = this._get_search_query();

		var filtered_items = [];
		for (var i = 0; i < venues.length; i++) {
			var vname = (venues[i].internal_name || venues[i].name).toLowerCase();
			if (vname.indexOf(search_q) === -1) {
				continue;
			}
			filtered_items.push(venues[i]);
			if (this._useDisplayedItemsCountLimit && filtered_items.length === 20) {
				break;
			}
		}
		this._display_items(filtered_items)
	},

	_display_items : function(venues) {
		var html_block = '';

		for (var i = 0; i < venues.length; i++) {
			var vname = _.escape(venues[i].internal_name || venues[i].name);
			var isFirst = (i === 0) ? 'selected' : '';
			var isLast = (i === venues.length - 1) ? 'last' : '';
			var html = "<div class='venue-search-result " + isFirst + " " + isLast + "'>" + vname +
				"<span id='venue_key' data-id='"+ venues[i].id + "' data-name='" + _.escape(venues[i].name) + "' style='display: none;'>" +
					venues[i].url_key_or_id +
				"</span></div>";
			html_block += html;
		}
		$('#venue-search-results', $(this._scope)).html(html_block);
		$('#venue-search-results', $(this._scope)).removeClass('no-display');
		this._stopAnimating();
	},
};
