// This file was automatically generated from splash.ssologin.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Homepage == 'undefined') { Nightloop.Templates.Homepage = {}; }


Nightloop.Templates.Homepage.SSOLogin = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Homepage.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Homepage.SSOLoginBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Homepage.SSOLoginBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<meta name="robots" content="noindex"><div class="main login"><div class="content-wrapper"><div class="align"><div class="input-wrapper"><a href="', soy.$$escapeHtml(opt_data.sso_login_link), '"><div class="simple-btn blue">SSO Login</div></a></div></div></div></div><iframe class="main ad-container" src="', soy.$$escapeHtml(opt_data.wordpress_ad_url), '"></iframe>');
  return opt_sb ? '' : output.toString();
};
