// This file was automatically generated from splash.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Splash == 'undefined') { Nightloop.Templates.Splash = {}; }


Nightloop.Templates.Splash.Layout = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="splash"><div id="splash-container"><div id="splash-body">');
  Closure.Util.InsertTemplate(opt_data, output);
  output.append('</div></div></div>');
  return opt_sb ? '' : output.toString();
};
