var Account = {
  init: function () {
    this.bind()
  },

  bind: function () {
    $('#submit-account-form').on('click', this.updateaccount.bind(this))
    var $alertRadio = $('input[type=radio][name=email_alerts]')
    var $emailAlerts = $('.email-alerts')
    $alertRadio.change(function () {
      if (this.value === 'enabled') {
        $emailAlerts.show()
      } else if (this.value === 'disabled') {
        $emailAlerts.hide()
      }
    })
    if ($('#id_disabled').parents('.form-element').hasClass('checked')) {
      $emailAlerts.hide()
    }
  },

  updateaccount: function (e) {
    function submitFormFunc() {
      this.submitForm(e)
    }
    if ($('#account-new-password').val()) {
      var dialogType = 'WARNING'
      var modalHeader = 'ARE YOU SURE'
      var modalExplanation = 'Changing your password will log you out of all active sessions. You will need to log back in to continue.'
      var actionButtonText = 'Confirm'
      $('#message-box-container').removeClass('no-display')
      SvrManager.PortedElements.initMessageBox(
        'message-box-container',
        submitFormFunc.bind(this),
        this.hideModal,
        dialogType,
        modalHeader,
        modalExplanation,
        actionButtonText
      )
      return
    }
    this.submitForm(e)
  },

  hideModal: function () {
    $('#message-box-container').addClass('no-display')
  },

  submitForm: function (e) {
    var success = function (response) {
      if ($('#account-new-password').val()) {
        window.location.href = '/logout'
      }
      $('.password input').val('')
      $('#success').sext(response.payload.message)
      var borderValid = '1px solid #ccc'
      $('#account-old-password').css('border', borderValid)
      $('#account-new-password').css('border', borderValid)
      $('#account--password').css('border', borderValid)
      $('#message-box-container').addClass('no-display')
    }

    var error = function (response) {
      var obj = $.parseJSON(response.responseText)
      $('#errors').sext(obj.errors[0])
      var borderError = '1px solid red'
      $('#account-old-password').css('border', borderError)
      $('#account-new-password').css('border', borderError)
      $('#account--password').css('border', borderError)
      $('#message-box-container').addClass('no-display')
    }

    var $form = $(e.target).closest('form')
    var data = $form.serialize()

    $('#success').sext('')
    $('#errors').sext('')
    $.ajax({
      url: $form.prop('action'),
      method: $form.prop('method'),
      data: data,
      success: success,
      error: error,
    })
    CustomerSuccessTracker.trackCRM('Edit Profile')
  },
}

$(document).ready(function () {
  Account.init()
})
