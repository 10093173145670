// This file was automatically generated from direct.emailcenter.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Reservation == 'undefined') { Nightloop.Templates.Reservation = {}; }


Nightloop.Templates.Reservation.EmailUnsubscribe = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Reservation.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Reservation.EmailUnsubscribeBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Reservation.EmailUnsubscribeBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<meta name="viewport" content="width=device-width" /><div id="email-subscription-header-wrapper"><div id="email-subscription-header">Unsubscribe</div></div><div id="marketing-opt-in-header">Are you sure you want to unsubscribe from ', soy.$$escapeHtml(opt_data.content.venue_name), '\'s email list?</div><div id="email-subscriptions-body"><div id="email-subscription-submit-wrapper"><div id="email-subscription-submit-btn">Confirm</div><div id="error-msg"></div></div></div><script type="text/javascript">$(document).ready(function() {Pmp.Direct.EmailCenter.initialize("', soy.$$escapeHtml(opt_data.content.campaign_id), '", "', soy.$$escapeHtml(opt_data.content.venue_group_client_id), '");});<\/script>');
  return opt_sb ? '' : output.toString();
};
