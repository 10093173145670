
var Pmp = Pmp || {};
Pmp.Common = Pmp.Common || {};
Pmp.Common.Reservation = Pmp.Common.Reservation || {};

Pmp.Common.Reservation.Validator = {

  initialize : function(activeValidatorsList, afterValidationHookFn, onSubmitHookFn, submitForm, currency_symbol) {
    this._inValidationMode = false;
    this._currency_symbol = currency_symbol;
    this._submitForm = submitForm;
    this.setActiveValidatorsList(activeValidatorsList);
    this.trimManagerRequestsTextArea();
    this.trimPrivateNotesTextArea();
    this.trimPublicNotesTextArea();
    this.bindEventHandlers(afterValidationHookFn, onSubmitHookFn);
  },

  bindEventHandlers : function(afterValidationHookFn, onSubmitHookFn) {
    var self = this;
    // action handlers

    var $submit_button = $('#save-reservation, #client-info-submit-btn');

    $submit_button.click(function(event) {
      if ($(this).hasClass('disabled')) {
        return;
      }
      var shouldAutosubmitForm = true;
      $submit_button.removeClass('valid');
      var isValid = self.validateFormFields();
      if (isValid) {
        $submit_button.addClass('disabled pending valid');
        $('#client-info-submitting-notice').removeClass('no-display');
      }
      if (isValid && onSubmitHookFn) {
        shouldAutosubmitForm = onSubmitHookFn(event);
      }
      if (afterValidationHookFn) {
        afterValidationHookFn();
      }
      if (isValid && shouldAutosubmitForm) {
        if (self._submitForm) {
          $(self._submitForm).submit();
        } else {
          $submit_button.closest('form').submit();
        }
      }

      $('#manager').trigger('poppedcolorbox');
    });

    var trimmer = function() { self.trimManagerRequestsTextArea(); };
    $('#manager-requests-textarea').keyup(trimmer).change(trimmer);

    trimmer = function() { self.trimPrivateNotesTextArea(); };
    $('#venue-private-notes-textarea').keyup(trimmer).change(trimmer);

    trimmer = function() { self.trimPublicNotesTextArea(); };
    $('#venue-public-notes-textarea').keyup(trimmer).change(trimmer);

    // Wrap validation change handlers in function because they may return false
    $('#input-firstname').change(function() {self.validateFirstName();});
    $('#input-lastname').change(function() {self.validateLastName();});
    $('#input-email').change(function() {self.validateEmail();});
    $('#input-phone').change(function() {self.validatePhone();});
    $('#select-est-arrival-time').change(function() {self.validateEstArrival();});
    $('#party-size-override').change(function() {self.validatePartySizeOverride();});
    $('#min-price-override').change(function() {self.validateMinimumOverride();});
    $('#booked-by').change(function() {self.validateBookedBy();});
    $('#new-promoter-name').change(function() {self.validateBookedBy();});
    $('#select-seating-area').change(function() {self.validateSeatingArea();});
    $('input.cc-num').change(function() {self.validateCreditCardForm();});
    $('input.cc-exp-m').change(function() {self.validateCreditCardForm();});
    $('input.cc-exp-y').change(function() {self.validateCreditCardForm();});
    $('input.cc-cvc').change(function() {self.validateCreditCardForm();});


    // Add links to invalid notice area for returning to search
    function replaceSearchWithHTMLLink(text) {
        if (!text) { return ''; }
          var exp = 'search'
          var base_url = $('#concierge_url').html();
          var vertical = $('#concierge_vertical').html();
          return text.replace(exp,"<a class='ajaxify' href='"+base_url+"/" + vertical + "'>search</a>");
      }
    $('#top-invalid-notice').html(replaceSearchWithHTMLLink($('#top-invalid-notice').html()))

    // Blackberry PIN
    $('#bbm_radio').click(function() {
       if ($(this).is(':checked')) { $('#bbm_pin_block').show(); $.colorbox.resize(); }
    });
    $('#email_radio').click(function() {
       if ($(this).is(':checked')) { $('#bbm_pin_block').hide(); $('#invalid_bbm_pin').hide(); }
    });
    $('#text_radio').click(function() {
       if ($(this).is(':checked')) { $('#bbm_pin_block').hide(); $('#invalid_bbm_pin').hide(); }
    });
  },

  setActiveValidatorsList : function(activeValidatorsList) {
    this._activeValidatorsList = activeValidatorsList;
  },

  updateInvalidDisplay : function(isValid, invalidDiv) {
    if (!this._inValidationMode) {
      return;
    }
    if (isValid) {
      invalidDiv.hide();
      $('#invalid-all').hide();
    } else {
      invalidDiv.show();
      $('#invalid-all').show();
    }
  },

  isEmpty : function(value) {
    return $.trim(value).length == 0;
  },

  isActiveValidator : function(name) {
    return $.inArray(name, this._activeValidatorsList) != -1;
  },

  validateFirstName : function() {
    if (!this.isActiveValidator('firstName')) {
      return true;
    }

    var value = $('#input-firstname').val();
    var invalidDiv = $('#invalid-firstname');

    var isValid = !this.isEmpty(value);

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  validateLastName : function() {
    if (!this.isActiveValidator('lastName')) {
      return true;
    }
    var value = $('#input-lastname').val();
    var invalidDiv = $('#invalid-lastname');

    var isValid = !this.isEmpty(value);

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  isValidEmail : function(value) {
    // Source http://www.regular-expressions.info/email.html
    return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]([a-z0-9-]*[a-z0-9])?$/gi.test(value);
  },

  validateEmailOrPhone: function() {
    if (!this.isActiveValidator('emailOrPhone')) {
      return true;
    }
    var email = $('#input-email').val();
    var isValidEmail = this.isValidEmail(email);
    var phone = $('#input-phone').val();
    var phone_locale = $('#select-phone-locale').val();
    var isValidPhone = this.isValidPhone(phone, phone_locale);
    var invalidDivPhoneEmail = $('.invalid-phone-email');
    this.updateInvalidDisplay(isValidPhone || isValidEmail, invalidDivPhoneEmail);
    return isValidPhone || isValidEmail;
  },


  validateEmail : function() {
    if (!this.isActiveValidator('email')) {
      return true;
    }
    var value = $('#input-email').val();
    var invalidDiv = $('#invalid-email');

    var isValid = this.isValidEmail(value);

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  validateEmailOrEmpty : function() {
    if (!this.isActiveValidator('emailOrEmpty')) {
      return true;
    }
    var value = $('#input-email').val();
    var invalidDiv = $('#invalid-email');

    var isValid = true;
    value = $.trim(value);
    $('#input-email').val(value);
    if (!this.isEmpty(value)) {
      isValid = this.isValidEmail(value);
    }

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  isValidUrl : function(value) {
    // Validate url format - ported from Django's URLValidator
    var url_re_str = (
      "^https?://" // http:// or https://
      + "(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+[A-Z]{2,6}\\.?|" // domain...
      + "localhost|" // localhost...
      + "\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})" // ...or ip
      + "(?::\\d+)?" // optional port
      + "(?:/?|[/?]\\S+)$"
  )
    // Ignore-case
    var url_re = new RegExp(url_re_str, "i");
    return url_re.test(value);
  },

  isValidUrlOrEmpty : function(value) {
    return value === '' || this.isValidUrl(value);
  },

  isValidDate : function(input) {
    if (input.datepicker('getDate') === null) {
      input.datepicker('setDate', null); // This clears out the localized hidden input if the user cleared the visible input
      return false;
    } else {
      return true;
    }
  },

  isValidDateOrEmpty : function(input) {
    return this.isValidDate(input) || input.val() === ''; //  Call isValidDate first bc it does cleanup
  },

  isValidPhone : function(phone, phone_locale) {
    if (phone_locale === 'US') {
      phone = phone.replace(/[\+]1/ig, ''); // remove +1's
      phone = phone.replace(/[^\d]/ig, '');
      var phone_re = /^[+]?([1]{1})?[-\. ]?\(?([0-9]{3})\)?[-\. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      return phone_re.test(phone);
    } else {
      // INTL
      return true;
    }
  },

  validatePhone : function() {
    if (!this.isActiveValidator('phone')) {
      return true;
    }
    var invalidDiv = $('#invalid-phone');
    var phone = $('#input-phone').val();
    var phone_locale = $('#select-phone-locale').val();

    var isValid = this.isValidPhone(phone, phone_locale);

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  validatePhoneOrEmpty : function() {
    if (!this.isActiveValidator('phoneOrEmpty')) {
      return true;
    }
    var invalidDiv = $('#invalid-phone');
    var phone = $('#input-phone').val();
    var phone_locale = $('#select-phone-locale').val();

    var isValid = true;
    phone = $.trim(phone);
    if (!(this.isEmpty(phone))) {
      isValid = this.isValidPhone(phone, phone_locale);
    }

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  validateEstArrival : function() {
    if (!this.isActiveValidator('estArrival')) {
      return true;
    }
    var invalidDiv = $('#invalid-est-arrival');
    var time = $('#select-est-arrival-time').val();

    var isValid = !this.isEmpty(time);

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  validateSeatingArea : function() {
    if (!this.isActiveValidator('seatingArea')) {
      return true;
    }
    var invalidDiv = $('#invalid-seating-area');
    var seatingArea = $('#select-seating-area').val();

    var isValid = !this.isEmpty(seatingArea);

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  isValidIntOrEmpty : function(value) {
    return value.match(/^[0-9]*$/) !== null;
  },

  validatePartySizeOverride : function() {
    if (!this.isActiveValidator('partySizeOverride')) {
      return true;
    }
    var invalidDiv = $('#invalid-party-size');
    var value = $('#party-size-override').val();

    var isValid = true;
    value = $.trim(value);
    $('#party-size-override').val(value);
    isValid = this.isValidIntOrEmpty(value);

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  validateMinimumOverride : function() {
    if (!this.isActiveValidator('minOverride')) {
      return true;
    }
    var invalidDiv = $('#invalid-minimum');
    var value = $('#min-price-override').val();

    var isValid = true;

    value = Pmp.Utils.Currency.RawDecimal(value, this._currency_symbol);

    isValid = value.match(/^[0-9]*$/) !== null; // verify int or empty

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },

  validateBookedBy : function() {
    if (!this.isActiveValidator('bookedBy')) {
      return true;
    }
    var invalidDiv = $('#invalid-booked-by');
    var value = $('#booked-by').val();

    var isValid = true;
    var new_name = $('#new-promoter-name').val()
    if (value == '--new--' && this.isEmpty(new_name)) {
      isValid = false;
    }

    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;
  },


  validateCreditCardForm : function () {
    if (!this.isActiveValidator('creditCard')) {
      return true;
    }

    var cardNumber = $('input.cc-num').val();
    var exp_month = $('input.cc-exp-m').val();
    var exp_year = $('input.cc-exp-y').val();
    var cvc = $('input.cc-cvc').val();
    var cardType = $.payment.cardType(cardNumber);

    var isValid = $.payment.validateCardNumber(cardNumber);
    isValid = isValid && $.payment.validateCardExpiry(exp_month,exp_year);
    isValid = isValid && $.payment.validateCardCVC(cvc,cardType);

    var invalidDiv = $('.invalid-cc');
    this.updateInvalidDisplay(isValid, invalidDiv)
    return isValid;
  },

  validatePerkSelected : function() {
    if (!this.isActiveValidator('perkSelected')) {
      return true;
    }
    if (!$('input[name=perks_checked]').length) {
      return true;
    }
    var invalidDiv = $('#invalid-perks');
    var isValid = $('input[name=perks_checked]:checked').length > 0;
    this.updateInvalidDisplay(isValid, invalidDiv);
    return isValid;

  },

  validateFormFields : function() {
    this._inValidationMode = true;
    var isValid = true;

    isValid = this.validateFirstName() && isValid;
    isValid = this.validateLastName() && isValid;
    isValid = this.validateEmail() && isValid;
    isValid = this.validateEmailOrEmpty() && isValid;
    isValid = this.validatePhone() && isValid;
    isValid = this.validatePhoneOrEmpty() && isValid;
    isValid = this.validateEmailOrPhone() && isValid;
    isValid = this.validateEstArrival() && isValid;
    isValid = this.validatePartySizeOverride() && isValid;
    isValid = this.validateMinimumOverride() && isValid;
    isValid = this.validateBookedBy() && isValid;
    isValid = this.validateSeatingArea() && isValid;
    isValid = this.validateCreditCardForm() && isValid;
    isValid = this.validatePerkSelected() && isValid;

      // isValid = this.validateBBMPin() && isValid;
    var invalidAllDiv = $('#invalid-all');
    this.updateInvalidDisplay(isValid, invalidAllDiv);
    return isValid;
  },

  textAreaTrimmer : function(idprefix, maxlength) {
    var $node = $(idprefix + '-textarea');
    if ($node.length == 0) {
      return;
    }

    if (maxlength === undefined) {
      maxlength = parseInt($node.attr('maxlength'), 10);
    }

    var text = $node.val();
    if (text.length > maxlength) {
      $node.val(text.slice(0, maxlength));
    }
    var remaining = maxlength - $node.val().length;
    $(idprefix + '-remaining').sext(remaining + '');
  },

  trimPublicNotesTextArea : function() {
    this.textAreaTrimmer('#venue-public-notes', 1500);
  },

  trimPrivateNotesTextArea : function() {
    this.textAreaTrimmer('#venue-private-notes', 5000);
  },

  trimManagerRequestsTextArea : function() {
    this.textAreaTrimmer('#manager-requests');
  }

};
