var Pmp = Pmp || {};
Pmp.Admin = Pmp.Admin || {};
Pmp.Admin.VenueShell = Pmp.Admin.VenueShell || {};

Pmp.Admin.VenueShell.Edit = {
  _base_url: '/admin',

  initialize: function (options) {
    this.venue_group_select = new sr.AsyncSelect({
      select: options.root.querySelector('#venue-group-select'),
      selected_id: options.venue_group_id,
      service: Pmp.Admin.VenueGroupsService,
    });
    this.neighborhood_select = new sr.AsyncSelect({
      select: options.root.querySelector('#neighborhood-select'),
      selected_id: options.neighborhood_id,
      service: Pmp.Admin.NeighborhoodsService,
    });
    this.settings_source_venue_select = new sr.AsyncSelect({
      select: options.root.querySelector('#settings-source-venue-select'),
      selected_id: options.settings_source_venue_id,
      service: Pmp.Admin.SourceVenuesService,
    });
  },
};
