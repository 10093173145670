// This file was automatically generated from admin.showExperienceID.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.ShowExperienceID = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.ShowExperienceIDBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ShowExperienceIDBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="admin-enable-experience-id"><h1>Hide or Show Experience ID for Venue Access Rules</h1></div><div style="display: inline-flex;width:100%;justify-content: space-between;align-items: center;"><div style="flex-basis: min-content;"><form id=\'enable-form\' action="/admin/show-experience-id" method="post" class="ajaxify"><h5>Venue ID: <span style"color:red;">*</span></h5><div style="margin:15px;"><input value=\'', soy.$$escapeHtml(opt_data.content.venue_id), '\' id="enable-experience-id-post" type="text" name="venue-id" style="width:450px;" required/></div><h5>Access Persistent IDs: <span style"color:red;">*</span></h5><div style="margin:15px;"><p>Comma-separated list of Access Persistent IDs</p><textarea name="persistent-ids" style="width:450px;" required>', soy.$$escapeHtml(opt_data.content.persistent_ids), '</textarea></div><div style="margin-bottom:15px;"><h5 style="display:inline-block;margin-right:10px;">Hide or Show Experience ID in availability search:</h5><input id="enable-experience-id" type="checkbox" name="enable-experience-id" ', (opt_data.content.enable_experience_id) ? 'checked' : '', ' /></div><button type="submit" style="width:180px;">SUBMIT</button><p style="margin:10px;">', soy.$$escapeHtml(opt_data.content.success_message), '</p><p style="margin:10px;">', soy.$$escapeHtml(opt_data.content.error_message), '</p></form></div></div>');
  return opt_sb ? '' : output.toString();
};
