var Pmp = Pmp || {};
Pmp.Common = Pmp.Common || {};
Pmp.Common.Entity = Pmp.Common.Entity || {};

Pmp.Common.Entity.Users = {
	initialize: function() {
		this.bindEventHandlers();
	},

	bindEventHandlers: function() {
		$('#user-session-logout').click(function() {
			$.ajax({
				url: '/force-sessions-logout',
				type: 'POST',
				data: {},
				success: function (response) {
					if( !response ) { console.log("Error in submission"); }
				}
			});
		})
	}
}