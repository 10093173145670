// This file was automatically generated from blank.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Blank == 'undefined') { Nightloop.Templates.Blank = {}; }


Nightloop.Templates.Blank.Layout = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="black"><div id="logo"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/logo-big.png" /></div></div>');
  return opt_sb ? '' : output.toString();
};
