// This file was automatically generated from splash.forgotpasswordmobilewebview.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Homepage == 'undefined') { Nightloop.Templates.Homepage = {}; }


Nightloop.Templates.Homepage.ForgotPasswordMobileWebView = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="main login forgot"><div class="content-wrapper"><div class="align"><div class="form-wrapper"><form autocomplete="off" class="login-form" id="sign-in" method="POST" action="/forgot-password?is_mobile_web_view=True">');
  if (opt_data.submitted) {
    output.append('<div class="success"><p>If the user account exists, an email has been sent with a link to reset your password.</p><br/></div>');
  } else {
    Nightloop.Templates.Widget.Csrf(opt_data, output);
    output.append('<div style="margin-left:0" class="interface"><input autocomplete="off" id=\'login-username\' style="text-align:center" type="email" name="email" placeholder="enter email" class="text" /></div><br/><div class="input-group"><div class="input-wrapper"><input id="submit-login" type="submit" name="lsubmit" value="Reset password" class="simple-btn blue lsubmit" /></div></div>');
  }
  output.append('</form></div></div></div></div><script type="text/javascript">function focusLoginTextField() {$(this).siblings(\'.login-text\').hide();}function focusOutLoginTextField() {if ($(this).val().length == 0) {$(this).siblings(\'.login-text\').show();}}function clickLoginTextDiv() {$(this).hide(); $(this).siblings(\'input\').focus();}$(document).ready(function() {$(\'input\').placeholder(); $(\'.login-text-container input\').focus(focusLoginTextField); $(\'.login-text-container input\').focusout(focusOutLoginTextField); $(\'.login-text\').click(clickLoginTextDiv); Pmp.Utils.GoldButtonClickSubmit(\'#forgotpassword-reset-btn\'); $(\'.login-text-container input\').each(focusLoginTextField); $(\'.login-text-container input\').each(focusOutLoginTextField);});<\/script>');
  return opt_sb ? '' : output.toString();
};
