// This file was automatically generated from direct.error.server_error_500.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Direct == 'undefined') { Nightloop.Templates.Direct = {}; }


Nightloop.Templates.Direct.ServerError = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Splash.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Direct.ServerErrorBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Direct.ServerErrorBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-no-access"><div id="nl-logo"><div class="splash-box-opaque"></div><div><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/sevenrooms_logo.png" /></div></div><div id="body"><div class="splash-box"><div class="splash-box-content"><div><div class="contents-text">Whoops! Something went wrong.  Please wait a few moments and then try again.</div><p class="button"><a class="" href="/">Return to safety</a></p></div></div></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};
