// This file was automatically generated from main.popup.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Main == 'undefined') { Nightloop.Templates.Main = {}; }
if (typeof Nightloop.Templates.Main.Popup == 'undefined') { Nightloop.Templates.Main.Popup = {}; }


Nightloop.Templates.Main.Popup.PopupPlaceholder = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="no-display"><div id="main-popup-parent"><div id="main-popup"></div></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Main.Popup.Popup_OnReadyJs = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('Pmp.Main.Popup.Base.initialize();');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Main.Popup.PopupLayout = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="main-popup"><div id="', soy.$$escapeHtml(opt_data.div_id), '" class="main-popup-contents">', (opt_data.show_close_link == 'true') ? '<div class="float-right close-link"><a class="close-main-colorbox-link closer" href="javascript: void(0);">&times;</a></div>' : '', '<div class="overlay-header"><span class="mobile-identifier">', opt_data.header_html, '</span></div><div class="overlay-body">');
  Closure.Util.InsertTemplate(opt_data, output);
  output.append('</div></div></div>');
  return opt_sb ? '' : output.toString();
};
