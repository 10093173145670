// This file was automatically generated from admin.venue.logos.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenueLogosEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.VenueLogosEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueLogosEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-edit"><div class="images-upload-area"><div><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/edit">&lt;&lt; back to venue edit</a></div><h3>', soy.$$escapeHtml(opt_data.content.venue.name), ' Venue Logo</h3><div>', (opt_data.content.venue.logo_size_map) ? '<div style="width:335px;position:relative;text-align:center;background:' + soy.$$escapeHtml(opt_data.content.venue_color) + '"><img src="/.h/download/' + soy.$$escapeHtml(opt_data.content.venue.logo_size_map.large) + '" /><div style="position:absolute;top:0;right:0;bottom:0;left:0;border:2px dashed #F00;"></div></div>' : '[none uploaded]', '</div><div><form enctype="multipart/form-data" action="', soy.$$escapeHtml(opt_data.content.upload_form_url), '" method="post"><p>Upload new logo:<input type="file" name="logo" /></p><p>Your logo should:<ul><li>Have transparent background</li><li>Be no smaller than 335x140px</li><li>Keep an aspect ratio narrower than 2.4:1</li></ul></p><input type="submit" value="Submit"></form></div></div></div>');
  return opt_sb ? '' : output.toString();
};
