// This file was automatically generated from widget.csrf.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Csrf = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append((opt_data.csrf_token) ? '<input type="hidden" name="csrftoken" value="' + soy.$$escapeHtml(opt_data.csrf_token) + '" />' : '');
  return opt_sb ? '' : output.toString();
};
