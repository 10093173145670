// This file was automatically generated from splash.login.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Homepage == 'undefined') { Nightloop.Templates.Homepage = {}; }


Nightloop.Templates.Homepage.Login = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Homepage.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Homepage.LoginBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Homepage.LoginBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="main login"><div class="content-wrapper"><div class="align">', (opt_data.form.rurl == '/help?') ? '<div class="support-inquiry">SUPPORT INQUIRY?</div><div class="support-inquiry-text">Log in below to access our help center</div>' : '<div class="welcome-back">WELCOME BACK</div>', (opt_data.from_contactlink) ? '<p style="font-size: 18px; color: white"><em>Log in to contact our customer success team</em></p>' : '', '<div class="form-wrapper"><form autocomplete="off" class="login-form" method="post" id="login-form">');
  Nightloop.Templates.Widget.Csrf(opt_data, output);
  output.append((opt_data.form.rurl) ? '<input type="hidden" name="rurl" value="' + soy.$$escapeHtml(opt_data.form.rurl) + '" />' : '', '<div class="input-group"><div class="input-wrapper"><input autocomplete="off" type="email" name="email" placeholder="email" class="lemail" id="email" /></div></div><div class="input-group"><div class="input-wrapper"><input autocomplete="off" type="password" name="password" placeholder="password" class="lpassword" /></div></div>', (opt_data.is_captcha_required) ? '<div class="g-recaptcha" data-sitekey="6LfJwSQdAAAAAP5YxxNSgjvLgnsVFGK6o3PzT-oy"></div>' : '', (opt_data.form.errors) ? '<div class="error" style="padding-bottom: 15px">' + opt_data.form.errors + '</div>' : '', '<div class="input-group"><div class="input-wrapper"><input type="submit" name="lsubmit" value="Login" class="simple-btn blue lsubmit" /></div></div><a href="/forgot-password"><small class="blue-font">Oops, I forgot my password</small></a></form></div></div></div></div><iframe class="main ad-container" src="', soy.$$escapeHtml(opt_data.wordpress_ad_url), '"></iframe>', (opt_data.is_captcha_required) ? '<script src="https://www.google.com/recaptcha/enterprise.js" async defer><\/script>' : '', (opt_data.prefill_email) ? '<script defer>document.getElementById("email").defaultValue = \'' + opt_data.prefill_email + '\';<\/script>' : '');
  return opt_sb ? '' : output.toString();
};
