// This file was automatically generated from direct.error.firewall_error.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Direct == 'undefined') { Nightloop.Templates.Direct = {}; }


Nightloop.Templates.Direct.FirewallError = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Splash.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Direct.FirewallErrorBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Direct.FirewallErrorBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-no-access"><div id="nl-logo"><div class="splash-box-opaque"></div><div><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/sevenrooms_logo.png" /></div></div><div id="body"><div class="splash-box"><div class="splash-box-content"><div style="text-align: left;"><div class="contents-text">Oh no!  We detected a firewall on your network that doesn\'t like our servers.  Please test the following button, which should show a bunch of code text in a new tab in your browser:</div><p class="button"><a class="" href="', soy.$$escapeHtml(opt_data.test_url), '" target="_blank">Test Link</a></p><div class="contents-text"><p>If that link is blocked, please contact your IT department and ask that they add the "sevenrooms.co" domain to your firewall whitelist. If that page seems to load, please contact SevenRooms support at <a href="mailto:support@sevenrooms.com">support@sevenrooms.com</a> so that we can assist you further.</p><div>In the meantime,<ul style="margin: 0 1em;"><li style="padding: 5px;">You can try connecting to a different internet network (perhaps a different WiFi that is not subject to this firewall).</li><li style="padding: 5px;">You could also try a different internet browser in case the firewall settings are specific to your browser.</li><li style="padding: 5px;">Another option is to try an \'incognito\' browser session, which will disable any browser extensions or plugins that might be interfering with your network.</li><ul></div></div><p class="button"><a class="" href="/">Try again</a></p></div></div></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};
